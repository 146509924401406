import * as types from '../types/usuarios'

const INITIAL_STATE = {
    usuario: {},
    credenciados: [],
    empresa_atual: {},
    configs: {},
    modal_credenciado: false,
    loading: false,
    lista_usuarios: [],
    lista_page: 1,
    lista_perpage: 5,
    lista_total: 0,
    lista_filter: {}
}

export default (state = INITIAL_STATE, action) => {
  switch(action.type){
    case types.SET_USUARIO_LISTA_FILTER:
        return {...state, lista_filter: { ...state.lista_filter, ...action.payload }}
    case types.SET_USUARIO_LISTA_TOTAL:
        return {...state, lista_total: action.payload }
    case types.SET_USUARIO_LISTA_PERPAGE: 
        return {...state, lista_perpage: action.payload }
    case types.SET_USUARIO_LISTA_PAGE:
        return {...state, lista_page: action.payload }
    case types.LOAD_USUARIO_LISTA:
        return {...state, lista_usuarios: action.payload }
    case types.LOAD_USUARIO_CREDENCIADOS:
        return {...state, credenciados: action.payload }
    case types.LOAD_USUARIO_ATUAL:
        return {...state, usuario: action.payload }        
    case types.SET_MODAL_USUARIO_CREDENCIADOS:
        return {...state, modal_credenciado: action.payload }
    case types.SET_EMPRESA_ATUAL:
        return {...state, empresa_atual: action.payload}
    case types.LOAD_USUARIO_CONFIG:
        return {...state, configs: action.payload }
    case types.SET_USUARIO_LOADING:
        return {...state, loading: action.payload}
    default:
        return state
  }
}