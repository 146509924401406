import * as types from '../types/extrato'

const INITIAL_STATE = {
    ext_loading: true,
    ext_perpage: 10,
    ext_total: 0,
    ext_page: 0,
    conta_bancaria: {},
    credenciado: {},
    saldo: 0,
    aReceber: 0,
    ant_ped: {},
    dados_periodo: {entradas: 0, saidas: 0, taxas_cxpay: 0, liquido: 0, lista_extrato: []},
}

export default (state = INITIAL_STATE, action) => {
    switch(action.type){
        case types.SET_EXT_PAGE:
            return {...state, ext_page: action.payload}
        case types.SET_EXT_PERPAGE:
            return {...state, ext_perpage: action.payload}
        case types.SET_EXT_TOTAL:
            return {...state, ext_total: action.payload}
        case types.CONTA_BANCARIA:
            return {...state, conta_bancaria: action.payload}
        case types.CREDENCIADO:
            return {...state, credenciado: action.payload}
        case types.SALDO:
            return {...state, saldo: action.payload}
        case types.A_RECEBER:
            return {...state, aReceber: action.payload}
        case types.ANT_PEND:
            return {...state, ant_ped: action.payload}
        case types.DADOS_PERIODO:
            return {...state, dados_periodo: action.payload}
        case types.EXT_LOADING:
            return {...state, ext_loading: action.payload}
        default:
            return state
    }
}