import React, { } from 'react';
import { PortletHeader } from '../index';
import { Grid , Button, Typography} from '@material-ui/core';
import useStyles from './styles';

const RSHeaderBar = (props) => {
    const classes = useStyles();

    return (
        <PortletHeader>    
            <Grid className={classes.mt10}
                container
                direction="row"
                justify="flex-end"
                alignItems="center">
                    <div style={{flex: 1, flexDirection: "row", justifyContent: "flex-start"}}>
	                    <Typography  variant="h5" component="h5">{props.title}</Typography>
	                </div>
                {
                    props.buttons.map( (item, index) => {
                        return (
                            <Button style={{...item.style}} disabled={item.disabled} color={item.danger ? 'secondary' : 'primary'} size="small" onClick={item.onClick} key={index}>
                                {item.icon}&nbsp;{item.title}
                            </Button>
                        )
                    })
                }
            </Grid>
        </PortletHeader>
    );
}

export default RSHeaderBar;
