import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {  Icon,  Grid,  Paper,  Box,  Button,  Divider,} from "@material-ui/core";
import PerfectScrollBar from "react-perfect-scrollbar";
import dayjs from "dayjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import bancosdb from "../DadosBancarios/bancos";
import {  faCashRegister,  faWallet,  faCoins,} from "@fortawesome/free-solid-svg-icons";
import * as dashType from "../../store/types/dashboard";
import {  Portlet,  RSHeaderBar,  RSModal,  PortletLabel,  RSTextField,  RSLoading,  RSDataGrid,  RSNumberFormat,} from "../../components";
import Budget from "./components/budgetExt";
import TableListExt from "./components/tableListExt";
import useStyles from "./style";
import {  Add as AddIcon,  CalendarToday as CalendarTodayIcon,  Save as SaveIcon,  Close as CloseIcon,} from "@material-ui/icons";
import { loadResumoExt, loadAntecipaveis } from "../../store/actions/extrato";
import * as typesExt from "../../store/types/extrato";
import { loadContasBancarias } from "./../../store/actions/contas_bancarias";

const Extrato = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const daysResumo = useSelector((state) => state.dashboard);
  const { ext_loading, saldo, aReceber,  ext_perpage: per_page,  ext_total: total,  ext_page: page,  dados_periodo } = useSelector((state) => state.extrato);

  const { conta_bancaria, filtro } = useSelector((state) => state.conta_bancaria );


  const [modals, setModals] = useState({
    calendario: false,
    antecipaveis: false,
  });

  const [tabValue, setTabValue] = useState(0);

  function handleChangeTransactionPage(newPage) {
    dispatch([
      { type: typesExt.SET_EXT_PAGE, payload: newPage + 1 },
      Refresh({ ...daysResumo, page: newPage + 1, per_page: per_page }),
    ]);
  }

  const handleChangeTransactionRowsPerPage = (event) => {
    dispatch([
      { type: typesExt.SET_EXT_PAGE, payload: 1 },
      {
        type: typesExt.SET_EXT_PERPAGE,
        payload: parseInt(event.target.value, 10),
      },
      Refresh({
        ...daysResumo,
        page: 1,
        per_page: parseInt(event.target.value, 10),
      }),
    ]);
  };

  const trocaTab = (event, newValue) => {
    setTabValue(newValue);
  };

  const modifyDataInicio = (dias_red) => {
    dispatch([
      {
        type: dashType.SET_PERIODO_INICIO,
        payload: dayjs().subtract(dias_red, "day").format("YYYY-MM-DD"),
      },
    ]);
  };

  const modifyData = (type, newData) => {
    if (type == "inicio") {
      dispatch([{ type: dashType.SET_PERIODO_INICIO, payload: newData }]);
    } else if (type == "fim") {
      dispatch([{ type: dashType.SET_PERIODO_FIM, payload: newData }]);
    } else {
      console.log("tipo desconhecido!");
    }
  };



  const [buttonsTop, setButtonsTop] = useState([
    {
      danger: false,
      title: "Hoje",
      onClick: () => {
        modifyDataInicio(0);
        Refresh();
      },
    },
    {
      danger: false,
      title: "7 Dias",
      onClick: () => {
        modifyDataInicio(7);
        Refresh();
      },
    },
    {
      danger: false,
      title: "15 Dias",
      onClick: () => {
        modifyDataInicio(15);
        Refresh();
      },
    },
    {
      danger: false,
      title: "30 Dias",
      onClick: () => {
        modifyDataInicio(30);
        Refresh();
      },
    },
    {
      danger: false,
      title: "45 Dias",
      onClick: () => {
        modifyDataInicio(45);
        Refresh();
      },
    },
    {
      danger: false,
      title: "60 Dias",
      onClick: () => {
        modifyDataInicio(60);
        Refresh();
      },
    },
    {
      danger: false,
      title: "Filtrar",
      icon: <CalendarTodayIcon />,
      onClick: () => {
        setModals({ ...modals, calendario: true });
      },
    },
  ]);

  const Refresh = (parametros) => {
    loadAntecipaveis();
    showLoading();
    dispatch([loadResumoExt({ ...parametros })]);
    dispatch(loadContasBancarias());
  };

  const showLoading = () => {
    dispatch([{ type: typesExt.EXT_LOADING, payload: true }]);
  };

  const toCurrency = (value) => {
    let reais = new Intl.NumberFormat("pt-BR", {
      currency: "BRL",
      style: "currency",
    }).format(value);
    return reais;
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return <> {value === index && <Box>{children}</Box>} </>;
  }

  useEffect(() => {
    Refresh();
  }, []);

  return (
    <div className={classes.root}>
      <RSLoading loading={ext_loading} />
      <Portlet className={classes.titleMT}>
        <RSHeaderBar
          title={`Extrato - ${dayjs(daysResumo.data_inicio).format(
            "DD/MM/YYYY"
          )} Até ${dayjs(daysResumo.data_fim).format("DD/MM/YYYY")}`}
          buttons={buttonsTop}
        />

        <RSModal open={modals.calendario} modalWidth="30%" modalHeight="auto">
          {/* <PortletLabel title="Período" style={{ width: 100 + '%' }} /> */}
          <h5 style={{ paddingBottom: "0.4em" }}>
            Selecione o período para consulta
          </h5>
          <Divider />
          <Grid container style={{ marginTop: 30 }}>
            <Grid container item spacing={2}>
              <Grid item sm={6}>
                <RSTextField
                  typeinput="DATE"
                  label="Data Inicial"
                  value={daysResumo.data_inicio}
                  onChange={(event) => {
                    modifyData("inicio", dayjs(event).format("YYYY-MM-DD"));
                  }}
                />
              </Grid>
              <Grid item sm={6}>
                <RSTextField
                  typeinput="DATE"
                  label="Data Final"
                  value={daysResumo.data_fim}
                  onChange={(event) => {
                    modifyData("fim", dayjs(event).format("YYYY-MM-DD"));
                  }}
                />
              </Grid>
            </Grid>
            <div style={{ width: "100%", marginTop: "1.8em" }}>
              <Divider />
            </div>
            <Grid
              container
              direction="row"
              justify="flex-end"
              alignItems="center"
            >
              <Button
                color="primary"
                size="small"
                onClick={() => {
                  Refresh(daysResumo);
                  setModals({ ...modals, calendario: false });
                }}
              >
                {<SaveIcon />}&nbsp;Filtrar Período
              </Button>
              <Button
                color="secondary"
                size="small"
                onClick={() => {
                  setModals({ ...modals, calendario: false });
                }}
              >
                {<CloseIcon />}&nbsp;Cancelar
              </Button>
            </Grid>
          </Grid>
        </RSModal>

        <RSModal open={modals.antecipaveis} modalWidth={1080} modalHeight={650}>
                <Grid container spacing={3}>
                    <PortletLabel title="Lista de antecipaveis" style={{ width: 100 + '%' }} />
                    <h2>Selecione a transação para Antecipar</h2>
                    <Divider />
                    <Grid sm={12}>

                        <div className={classes.content}>
                            <Portlet className={classes.root}>
                               
                            <PerfectScrollBar>
                            <RSDataGrid
                              columns={[
                                {
                                  title: "ID DA TRANSAÇÃO",
                                  field: "transacao_id"
                                },
                                {
                                  title: "DATA TRANSAÇÃO",
                                  field: "transacao_data",
                                  render: rowData => dayjs(rowData.transacao_data).format('DD/MM/YYYY')
                                },
                                {
                                  title: "ID DA TRANSAÇÃO",
                                  field: "transacao_id",
                                },
                                {
                                  title: 'PARCELAS RESTANTES',
                                  field: 'parc_res'
                                },
                                {
                                  title: "VALOR TOTAL SEM ANTECIPAÇÃO",
                                  field: "val_s_ant",
                                  render: rowData => <RSNumberFormat prefix={'R$ '} value={rowData.val_s_ant} decimalScale={2}/>
                                },
                                {
                                  title: "VALOR COM ANTECIPAÇÃO TOTAL",
                                  field: "val_c_ant",
                                  render: rowData => <RSNumberFormat prefix={'R$ '} value={rowData.val_c_ant} decimalScale={2}/>
                                },
                              ]}
                              data={[{transacao_id: 12, transacao_data: '2021-05-05', pagamento_data: '2022-05-06', transacao_id: 345, parc_res: 5, val_s_ant: 244, val_c_ant: 225}]}
                              onRowClick={(event, rowData) => {
                               
                              }}
                              options={{
                                maxBodyHeight: 480,
                                minBodyHeight: 480,
                                headerStyle: {
                                  backgroundColor: "#F2F2F3",
                                  fontSize: 12,
                                  textAlign: "center",
                                  borderLeft: "solid 0.5px white",
                                },
                                cellStyle: {
                                  textAlign: "center",
                                  fontSize: 14,
                                },
                                rowStyle: (rowData) => ({
                                  fontSize: 20,
                                }),
                              }}
                            />
                          </PerfectScrollBar>

                            </Portlet>
                        </div>
                    </Grid>

                    <Grid container direction="row" justify="flex-end" alignItems="center" style={{ marginTop: 10 }}>
                        <Button color="primary" size="small" onClick={() => setModals({ ...modals, antecipaveis: false })}>
                            {<Icon className="fas fa-share-square" />}&nbsp;Solicitar antecipação
                        </Button>
                        <Button color="secondary" size="small" onClick={() => { setModals({ ...modals, antecipaveis: false }); }}>
                            {<Icon className="far fa-window-close" />}&nbsp;Cancelar
                        </Button>
                    </Grid>

                </Grid>
            </RSModal>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: 10,
                padding: 25,
                width: '70%',
              }}
            >
                {conta_bancaria?.map((item) => {
                  return (
                    <>
                      {item.tipo_conta == "P" ? (
                        <div>
                          <Grid container spacing={1}>
                          <Grid item xs={12} sm={4}>
                              <h4>Recebedor</h4>{item.titular.length > 0 ? item.titular : '-'}
                            </Grid>
                          <Grid item xs={12} sm={4}>
                              <h4>Banco</h4>{item.codigo_bancario} - {bancosdb.find( bank => bank.id === item.codigo_bancario).name}
                            </Grid>
                          <Grid item xs={12} sm={2}>
                              <h4>Conta</h4>{item.conta}-{item.digito}
                            </Grid>
                          <Grid item xs={12} sm={2}>
                              <h4>Agencia</h4>{item.agencia}
                            </Grid>
                          
                          </Grid>
                        </div>
                      ) : (
                        ""
                      )}
                      
                    </>
                  );
                })}
            </div>

    
        <div style={{ width: "95%", margin: "auto", marginBottom: "0.5em" }}>
          <Grid container sm={12} spacing={1}>
            <Budget
              style={{ border: "none", boxShadow: "0px 0px 9px 2px #eaeaea" }}
              grid={4}
              title="Saldo Atual"
            >
              <div>
                <span className={[classes.boldTitle]}>
                  {` ${toCurrency(saldo.toFixed(2)) || "R$ 0.00"}`}{" "}
                </span>
                <br />
                <span className={classes.descriptCliente}>
                  Disponivel para saque: R${" "}
                  {`${(saldo * 0.65).toFixed(2) || "0.00"}`}
                </span>
              </div>

              <div
                style={{
                  backgroundColor: "#2C526F",
                  padding: "15px 15px",
                  borderRadius: "50%",
                  color: "#fff",
                  marginRight: "1.5em",
                }}
              >
                <FontAwesomeIcon icon={faWallet} />
              </div>
            </Budget>

            <Budget
              style={{ border: "none", boxShadow: "0px 0px 9px 2px #eaeaea" }}
              grid={4}
              title="À Receber"
            >
              <div>
                <span className={classes.boldTitle}>
                  {" "}
                  {` ${toCurrency(aReceber.toFixed(2)) || "R$ 0.00"}`}
                </span>
                <br />
                <span
                  className={classes.descriptCliente}
                >{`Antecipe e receba antes`}</span>
              </div>

              <div
                style={{
                  backgroundColor: "#2C526F",
                  padding: "15px 15px",
                  borderRadius: "50%",
                  color: "#fff",
                  marginRight: "1.5em",
                }}
              >
                <FontAwesomeIcon icon={faCashRegister} />
              </div>
            </Budget>

            <Budget
              style={{ border: "none", boxShadow: "0px 0px 9px 2px #eaeaea" }}
              grid={4}
              title="Antecipações pendentes"
            >
              <div>
                <span
                  className={classes.descriptCliente}
                >{`Nenhuma antecipação disponível`}</span>
              </div>
              <div
                style={{
                  backgroundColor: "#2C526F",
                  padding: "15px 15px",
                  borderRadius: "50%",
                  color: "#fff",
                  marginRight: "1.5em",
                }}
              >
                <FontAwesomeIcon icon={faCoins} />
              </div>
            </Budget>
          </Grid>
        </div>
        <Grid container sm={12} style={{ flexBasis: "50%" }}>
          <Paper className={classes.rootTab}>
            {/* <Tabs
              value={tabValue}
              onChange={trocaTab}
              indicatorColor="primary"
              textColor="primary"
              centered
            >
              <Tab label="Anteriores" value={0} />
              <Tab label="A receber" value={1} />
            </Tabs> */}
            <TabPanel value={tabValue} index={0}>
              <TableListExt
                dados={{ dados_periodo, page, per_page, total }}
                changes={{
                  handleChangeTransactionPage,
                  handleChangeTransactionRowsPerPage,
                }}
              />
            </TabPanel>

            <TabPanel value={tabValue} index={1}>
              <TableListExt
                dados={{ dados_periodo, page, per_page, total }}
                changes={{
                  handleChangeTransactionPage,
                  handleChangeTransactionRowsPerPage,
                }}
              />
            </TabPanel>
          </Paper>
        </Grid>
      </Portlet>
    </div>
  );
};

export default Extrato;
