import React, {} from 'react';
import { makeStyles } from '@material-ui/styles';
import theme from '../../theme';
import { Modal, Backdrop, Fade} from '@material-ui/core';

const useStyles = makeStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      borderRadius: 10,
      position: 'absolute',
      backgroundColor: "#FFFFFF",
    },
});

const RSModal = (props) => {
    const classes = useStyles();
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={props.open}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={props.open}>
                <div className={classes.paper} style={{...props.style, width: props.modalWidth || 880, height: props.modalHeight || 550}}>
                    { props.children }
                </div>
            </Fade>
        </Modal>
    );
}

export default RSModal;
