import { makeStyles } from "@material-ui/styles";
import theme from "../../../theme";

const useStyles = makeStyles({
  root: {
    padding: theme.spacing() * 2,
  },
  gradient: {
    background: "rgb(255,255,255)",
    background:
      "linear-gradient(135deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 45%, rgba(107,114,116,1) 100%)",
  },
  titleVis: {
    fontSize: 14,
    color: "#5b5959",
  },
  resultVis: {
    fontSize: 30,
    marginTop: 10,
  },
  stylePaper: {    
    marginTop: "2em",
  },
  styleStatusCheck: {
    fontSize: "22px",
    marginTop: "0.5em",
    color: "#3d3d3d",
  },
  paga: { backgroundColor: "#65A300", color: "white", fontSize: 14 },
  suspensa: { backgroundColor: "#C25409", color: "white", fontSize: 14 },
  analise: { backgroundColor: "#235894", color: "white", fontSize: 14 },
  autorizada: { backgroundColor: "#23d143", color: "white", fontSize: 14 },
  estorno_pendente: {
    backgroundColor: "#3F2787",
    color: "white",
    fontSize: 14,
  },
  indisponivel: { backgroundColor: "#72737A", color: "white", fontSize: 14 },
  cancelada: { backgroundColor: "#660909", color: "white", fontSize: 14 },
  revisao_pendente: {
    backgroundColor: "#EC6E15",
    color: "white",
    fontSize: 14,
  },
  aguardando_pgto: { backgroundColor: "#F3B700", color: "white", fontSize: 14 },
  chargeback: { backgroundColor: "#070817", color: "white", fontSize: 14 },
  processando: { backgroundColor: "#5691D6", color: "white", fontSize: 14 },
  cbk_reapresentado: {
    backgroundColor: "#A71A44",
    color: "white",
    fontSize: 14,
  },
  estornada: { backgroundColor: "#6045AF", color: "white", fontSize: 14 },
  fraude_coberta: { backgroundColor: "#E2EFFF", color: "black", fontSize: 14 },
  recusada: { backgroundColor: "#DF285F", color: "white", fontSize: 14 },
  desconhecido: { backgroundColor: "#FFFFFF", color: "black", fontSize: 14 },
  btnEstorno: {
    border: "none",
    color: "#fff",
    background: "#282828",
    padding: "12px 15px",
    marginTop: "2em",
    borderRadius: "4px",
    cursor: 'pointer'
  },
});

export default useStyles;
