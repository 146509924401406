import React, { } from 'react';
import NumberFormat from 'react-number-format';

const RSNumberFormat = (props) => {
    return (
      <NumberFormat 
        {...props}
        displayType={'text'} 
        decimalSeparator={','} 
        thousandSeparator={'.'} 
        fixedDecimalScale={true} 
      />
    )
}

export default RSNumberFormat;
