import * as types from '../types/resumo'
import queryString from 'query-string'
import http from '../../services/http'
import store from '../index'

export const loadResumo = (filtro) => {
    let filter = {...filtro, ...store.getState().dashboard}
    const qry = queryString.stringify(filter, {arrayFormat: 'index'});
    return dispatch => {
        http.get(`/api/v1/adm/resumo/carregar?${qry}`).then(
            resp => {
                let response = resp.data
                dispatch([
                    {type: types.LOADING_RESUMO, payload: false},
                    {type: types.SET_RESUMO_ESTATISTICAS, payload: {
                            numero_transacoes: response.numero_transacoes, 
                            ticket_medio: response.ticket_medio, 
                            volume_transacionado: response.volume_transacionado,
                            transacoes_recusadas: response.transacoes_recusadas,
                            transacoes_por_status: response.transacoes_por_status,
                            formas_de_pagamento: response.formas_de_pagamento,
                            bandeiras_utilizadas: response.bandeiras_utilizadas,
                            quantidade_parcelas_cc: response.quantidade_parcelas_cc,
                            indices_conversao: response.indices_conversao,
                            volume_dia_semana: response.volume_dia_semana,
                        }
                    }
                ])
            },
            error => {
                dispatch({type: types.LOADING_RESUMO, payload: false});
            }
        )
    }
}