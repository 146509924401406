import * as types from '../types/sidebar'

const INITIAL_STATE = {
  width: 60,
  usuario: {}
}
  
export default (state = INITIAL_STATE, action) => {
  switch(action.type){
    case types.TOGGLE_SIDEBAR_WIDTH:
      return {...state, width: action.payload.width}
    case types.USER_NAME:
      return {...state, usuario: action.payload}
    default:
      return state
  }
}