import { Settings } from "@material-ui/icons";
import React from "react";
import { Divider } from "@material-ui/core";
import { Portlet } from "../../components";
import useStyles from './styles'

function Cadastro() {

    const classes = useStyles()

  return (
    <div className={classes.root}>        
    <Portlet className={classes.titleMT}>
      <div style={{display: 'flex', flexDirection: 'row', padding: '1.2em'}}><Settings /> &nbsp; Minha Conta</div>
     <Divider />
      aaa
      </Portlet>
    </div>
  );
}

export default Cadastro;
