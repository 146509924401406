import { makeStyles } from '@material-ui/styles';
import theme from '../../theme'

const useStyles = makeStyles({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 5,
        color: '#8FC18A',
        background: 'rgba(0,0,0,0.1)'
    },
})

export default useStyles;