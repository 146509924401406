import * as types from '../types/usuarios'
import queryString from 'query-string'
import http from '../../services/http'

export const loginApp = (email, senha, credenciado_id, navigate) => {
    let params = { email, senha, credenciado_id, navigate }
    return dispatch => {
        http.post('/api/v1/adm/usuario/autenticar', params).then( response => {
            const { usuario, credenciados, empresa_atual, token} = response.data
        
            if (!token) {
                if(credenciados.length > 0){
                    if (credenciados.length === 1){
                        dispatch(loginApp(email, senha, credenciados[0].id, navigate))
                    } else {
                        dispatch([
                            {type: types.LOAD_USUARIO_CREDENCIADOS, payload: credenciados},
                            {type: types.LOAD_USUARIO_ATUAL, payload: usuario},
                            {type: types.SET_MODAL_USUARIO_CREDENCIADOS, payload: true},
                            {type: types.SET_USUARIO_LOADING, payload: false}
                        ])
                    }
                } else {
                    dispatch([
                        {type: types.LOAD_USUARIO_CREDENCIADOS, payload: []},
                        {type: types.LOAD_USUARIO_ATUAL, payload: {}},
                        {type: types.SET_MODAL_USUARIO_CREDENCIADOS, payload: false},
                        {type: types.SET_USUARIO_LOADING, payload: false}
                    ])
                }
            } else {
                localStorage.setItem('token', token);
                localStorage.setItem('isAuthenticated', true);
                dispatch([
                    { type: 'SET_MENU', payload: []},
                    {type: types.LOAD_USUARIO_CREDENCIADOS, payload: credenciados},
                    {type: types.LOAD_USUARIO_ATUAL, payload: usuario},
                    {type: types.SET_MODAL_USUARIO_CREDENCIADOS, payload: false},
                    {type: types.SET_EMPRESA_ATUAL, payload: empresa_atual},
                    {type: types.SET_USUARIO_LOADING, payload: false},
                ])          
                navigate('/dashboard/resumo', {replace: true})
            }
        }, error => {
            dispatch([
                {type: types.SET_MODAL_USUARIO_CREDENCIADOS, payload: false},
                {type: types.SET_USUARIO_LOADING, payload: false},
            ])
        })
    }
}

export const trocar_empresa_usuario = (empresa_id) => {
  http.post('/api/v1/usuarios/trocar_empresa', { nova_empresa_id: empresa_id }).then( response => {
    const { token} = response.data
    localStorage.setItem('token', token);
    window.location.reload();
  })
}

export const trocar_senha_usuario = (password) => {
  return new Promise( (resolve, reject) => {
    http.post('/api/v1/usuarios/trocar_senha', { password }).then( 
      response => resolve(response.data),
      err => reject(err)
    )
  })
}

export const recuperar_senha_usuario = (email) => {
    return new Promise( (resolve, reject) => {
        http.post('/api/v1/usuarios/recuperar_senha',{email}).then(
            response => resolve(response),
            err => reject(err)
        )
    })
}

export const listaUsuarios = (filtro) => {
    const qry = queryString.stringify(filtro, {arrayFormat: 'index'});
    return dispatch => {
        http.get(`/api/v1/usuarios/listar?${qry}`).then( resp => {
            const {total, data} = resp.data
            dispatch([
                { type: types.SET_USUARIO_LISTA_TOTAL, payload: total},
                { type: types.LOAD_USUARIO_LISTA, payload: data},
                { type: types.SET_USUARIO_LOADING, payload: false }
            ])
        }, err => {
            dispatch({ type: types.SET_USUARIO_LOADING, payload: false })
        })
    }
}

export const excluirUsuario = id => {
    return new Promise( (resolve, reject) => {
        http.delete(`/api/v1/usuarios/excluir/${id}`).then( res => resolve(), err => reject())
    })
}

export const criarUsuario = body => {
    return new Promise( (resolve, reject) => {
        http.post(`/api/v1/usuarios/simples`, body).then( resp => resolve(), e => reject())
    })
}

export const atualizarUsuario = body => {
    return new Promise( (resolve, reject) => {
        http.post(`/api/v1/usuarios/atualizar`, body).then( resp => resolve(), e => reject())
    })
}