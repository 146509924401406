import React, { useState } from "react";
import { Divider, Grid, InputAdornment,Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Portlet, InputMask } from "../../../components";
import { Info as InfoIcon, MonetizationOn, Receipt } from "@material-ui/icons";
import { useSelector, useDispatch } from "react-redux";
import { ArrowLeftCircle, ArrowRightCircle } from "react-feather";
import DadosBancarios from "./dados_bancarios";

const useStyles = makeStyles({
  dados: {
    textAlign: "center",
    marginTop: "2em",
    padding: "2em",
    fontSize: "14px",
    width: "90%",
    margin: "auto",
  },
  confirm: {
    color: "#0d85bc",
    border: "1px solid #d3d3d3",
    height: '25px',
    borderRadius: "4px 0px 0px 4px",
  },
  noconfirm: {
    color: "#4c4c4c",
    border: "1px solid #d3d3d3",
    height: '25px',
  },
  information: {
    width: "98%",
    margin: "auto",
    marginTop: "1em",
    marginBottom: "1em",
    padding: "5px",
    backgroundColor: "#E2EFFF",
    color: "#235894",
    fontSize: "14px",
    borderRadius: "4px",
    display: "flex",
    flexDirection: "row",
    // justifyContent: 'center',
    alignItems: "center",
  },
  contasbancarias: {
    width: "96%",
    padding: "5px",
    margin: "auto",
    marginTop: "2em",
    color: "#454550",
    lineHeight: "1.8em",
    textAlign: "center",
    "& h4": {
      fontWeight: "normal",
      color: "gray",
    },
  },
  cardSaque:{
    boxShadow: '0px 2px 10px 0px #cecece',  
    borderRadius: '5px',
    height: "170px",
    paddingTop: '2em',
    paddingBottom: '1em',
  },
  NextButton:{
    width: '90%',
    padding: '2em',
    textAlign: 'right',
   '& button':{
     backgroundColor: '#1E4766',
     color: '#fff',
     padding: '12px 15px',
     '&:hover':{
       backgroundColor: '#1b669b'
     },
     '&:disabled':{
       backgroundColor: '#d3d3d3',
       color: '#fff'
     }
   }
  }
});

const toCurrency = (value) => {
  let reais = new Intl.NumberFormat("pt-BR", {
    currency: "BRL",
    style: "currency",
  }).format(value);
  return reais;
};

function Dados({ next, valor, handleChange, date }) {
  const { saldo } = useSelector((state) => state.extrato);
  const classes = useStyles();



  const valorfinal = toCurrency(valor.saque - valor.taxa);

  return (
    <>
       <div style={{display: 'flex', flexDirection: 'row', padding: '1.2em'}}><Receipt />&nbsp;Saque</div>
      <Divider />
      <div className={classes.dados}>
        <Grid container spacing={1} alignItems="center">
          <Grid item sm={4} xs={4} className={classes.confirm}>
            <b>1.</b> DADOS
          </Grid>
          <Grid item sm={4} xs={4} className={classes.noconfirm}>
            <b>2.</b> CONFIRMAÇÃO
          </Grid>
          <Grid item sm={4} xs={4} className={classes.noconfirm}>
            <b>3.</b> CONCLUSÃO
          </Grid>
        </Grid>
      </div>
      <Portlet>
        <DadosBancarios/>             
        <Grid
          container
          spacing={1}
          alignItems="center"
          className={classes.contasbancarias}
        >
          <Grid item sm={12} xs={12} >
          <div className={classes.cardSaque}>
            DIGITE O VALOR QUE DESEJA SACAR
            <h5>Saldo disponível {toCurrency(saldo)}</h5>
            <InputMask
              type="cash"
              label="Valor do Saque"
              style={{ width: "20%" }}
              value={valor.saque}
              onValueChange={(v) => handleChange({...valor, saque: v.floatValue || 0})}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MonetizationOn />
                  </InputAdornment>
                ),
              }}
            />
            <br/>
            {valor.saque > saldo  ? <><b style={{fontSize: '12px', color: '#dd3737',}}>Valor digitado superior ao saldo disponível ! {toCurrency(saldo)}</b></> : ''}<br/>
            
            </div>            
          </Grid>
          <Grid item sm={3} xs={3} >
            <div className={classes.cardSaque}>
            <h4>Valor Solicitado</h4>
            <h3> {date}</h3>
            </div>
          </Grid>
          <Grid item sm={3} xs={3} >
          <div className={classes.cardSaque}>
            <h4> Valor Solicitado</h4>
            <h3> {toCurrency(valor.saque)}</h3>
            </div>
          </Grid>
          <Grid item sm={3} xs={3}>
          <div className={classes.cardSaque}>
            <h4> Taxa de transferência</h4>
            <h3> {toCurrency(valor.taxa)}</h3>
            </div>
          </Grid>
          <Grid item sm={3} xs={3}>
          <div className={classes.cardSaque}>
            <h4>Valor a ser transferido</h4>
            <h3 >{valor.saque > saldo  ?  '' : <b style={{color: 'green'}}>+</b> } {valorfinal < "R$ 0,00" || valor.saque > saldo  ? 'R$ 0,00' : valorfinal}</h3>
            <h6>Saldo disponível após a transação {toCurrency(saldo - valor.saque)}</h6>
            </div>
          </Grid>
        </Grid>
        <div className={classes.NextButton}>
        <Button onClick={next} disabled={valor.saque > saldo || valorfinal < "R$ 0,00" ? true : false} >Próximo&nbsp;<ArrowRightCircle size="15"/> </Button>
        </div>
      </Portlet>
    </>
  );
}

export default Dados;
