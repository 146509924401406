// import { createMuiTheme, colors } from '@material-ui/core';
// import shadows from './shadows';
// import typography from './typography';

// const theme = createMuiTheme({
//   palette: {
//     background: {
//       dark: '#F4F6F8',
//       default: colors.common.white,
//       paper: colors.common.white
//     },
//     primary: {
//       main: '#1A718C'
//     },
//     secondary: {
//       main: colors.indigo[500]
//     },
//     text: {
//       primary: colors.blueGrey[900],
//       secondary: colors.blueGrey[600]
//     }
//   },
//   shadows,
//   typography
// });

// export default theme;

// Material helpers


import { createMuiTheme } from '@material-ui/core';

import palette from './palette';
import typography from './typography';
import overrides from './overrides';

const theme = createMuiTheme({
  palette,
  typography,
  overrides,
  zIndex: {
    appBar: 1200,
    drawer: 1100
  }
});

export default theme;
