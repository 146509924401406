import React, { } from 'react';
import {TablePagination} from '@material-ui/core';

const Paginate = (props) => {
    const { rowsPerPageOptions, count, rowsPerPage, page, onChangePage, onChangeRowsPerPage } = props;

    return (
        <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{ 'aria-label': 'página anterior',}}
            nextIconButtonProps={{ 'aria-label': 'próxima página', }}
            style={{}}
            onChangePage={onChangePage}
            onChangeRowsPerPage={onChangeRowsPerPage}
            labelRowsPerPage="Registros por página:"
        />
    );
}

export default Paginate;