
import React, {useState, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import dayjs from 'dayjs'
import * as FeatherIcon from 'react-feather'

import { 
    Tooltip, Grid, Button, Typography,Divider, TableContainer, Table, TableHead,TableRow,TableCell,TableBody
} from '@material-ui/core';

import * as dashType from '../../store/types/dashboard';
import * as types from '../../store/types/resumo';

import {
    Portlet, RSHeaderBar, RSModal, PortletLabel, 
    RSTextField, RSLoading
} from '../../components'

import {
    Budget, BudgetChart, BudgetList, InsertLegenda,
    BarChartPgto, BudgetPieChart, BudgetCardinalDays
} from './components'

import useStyles from './style'
import {
    CalendarToday as CalendarTodayIcon,
    Save as SaveIcon,
    Close as CloseIcon,
} from '@material-ui/icons'

import {loadResumo} from '../../store/actions/resumo'
import CardComponent from './components/CardComponent';

const Resumo = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const daysResumo = useSelector((state) => state.dashboard);
  const { resumo_estatisticas, loading } = useSelector((state) => state.resumo);



  const [modals, setModals] = useState({ calendario: false})
  
  const modifyDataInicio = (dias_red) => {
      dispatch([
          {type: dashType.SET_PERIODO_INICIO, payload: dayjs().subtract(dias_red, 'day').format('YYYY-MM-DD')}
      ])
  }

  const modifyData = (type, newData) => {
      if(type == 'inicio'){
          dispatch([
              {type: dashType.SET_PERIODO_INICIO, payload: newData}
          ])
      }else if( type == 'fim'){
          dispatch([
              {type: dashType.SET_PERIODO_FIM, payload: newData}
          ])
      }else{
          console.log('tipo desconhecido!')
      }
  }




const [buttonsTop, setButtonsTop] = useState([
  {danger: false, title: "Hoje",    onClick: () => { modifyDataInicio(0); Refresh()}},
  {danger: false, title: "7 Dias",  onClick: () => { modifyDataInicio(7); Refresh()}},
  {danger: false, title: "15 Dias", onClick: () => { modifyDataInicio(15); Refresh()}},
  {danger: false, title: "30 Dias", onClick: () => { modifyDataInicio(30); Refresh()}},
  {danger: false, title: "45 Dias", onClick: () => { modifyDataInicio(45); Refresh()}},
  {danger: false, title: "60 Dias", onClick: () => { modifyDataInicio(60); Refresh()}},
  {danger: false, title: "Filtrar", icon: <CalendarTodayIcon />, onClick: () => { setModals({...modals, calendario: true}) }},
])


  const Refresh = () => {
    return dispatch([
      { type: types.LOADING_RESUMO, payload: true },
      loadResumo(),
    ]);
  };

  const toCurrency = (value) => {
    let reais = new Intl.NumberFormat("pt-BR", {
      currency: "BRL",
      style: "currency",
    }).format(value);
    return reais;
  };


  useEffect(() => {
    Refresh();
  }, []);

  return (
    <div className={classes.root}>
      <RSLoading loading={loading} />
      <Portlet className={classes.titleMT}>
      <RSHeaderBar title={`Resumo - ${dayjs(daysResumo.data_inicio).format('DD/MM/YYYY')} Até ${dayjs(daysResumo.data_fim).format('DD/MM/YYYY')}`} buttons={buttonsTop}/>

        <RSModal open={modals.calendario} modalWidth='30%' modalHeight='auto'>
        {/* <PortletLabel title="Período" style={{ width: 100 + '%' }} /> */}
        <h5 style={{paddingBottom: '0.4em'}}>Selecione o período para consulta</h5>
        <Divider />
        <Grid container style={{ marginTop: 30 }}>
            <Grid container item spacing={2}>
                <Grid item sm={6}>
                    <RSTextField typeinput="DATE" label="Data Inicial" value={daysResumo.data_inicio} onChange={event => modifyData('inicio', dayjs(event).format('YYYY-MM-DD'))}/>
                </Grid>
                <Grid item sm={6}>
                    <RSTextField typeinput="DATE" label="Data Final" value={daysResumo.data_fim} onChange={event => modifyData('fim', dayjs(event).format('YYYY-MM-DD'))} />
                </Grid>

            </Grid>
            <div style={{width: '100%', marginTop: '1.8em'}}>
              
            <Divider />
            </div>
            <Grid container direction="row" justify="flex-end" alignItems="center">
                
                <Button color="primary" size="small" onClick={() =>{ Refresh(); setModals({...modals, calendario: false}) }} >
                    {<SaveIcon />}&nbsp;Filtrar Período
                </Button>
                <Button color="iconary" size="small" onClick={() => { setModals({ ...modals, calendario: false }) }}>
                    {<CloseIcon />}&nbsp;Cancelar
                </Button>
            </Grid>
        </Grid>
        </RSModal>
        <Grid
          container
          sm={12}
          spacing={1}
          style={{ flexBasis: "50%", padding: "1em" }}
        >
          <Grid item container spacing={1} sm={9}>
            <CardComponent
              grid={4}
              title="Número de transacoes"
              first={resumo_estatisticas.numero_transacoes}
              icon={<FeatherIcon.Repeat size={25} color="#65A300" />}
            />   
            <CardComponent
              grid={4}
              title="Ticket médio"
              first={<> {toCurrency(resumo_estatisticas.ticket_medio)} </>}
              icon={<FeatherIcon.TrendingUp size={25} color="#7052C8" />}
            />
            <CardComponent
              grid={4}
              title="Volume transacionado"
              first={<> {toCurrency(resumo_estatisticas.volume_transacionado)} </>}
              icon={<FeatherIcon.PieChart size={25} color="#4079BB" />}
            />        

            <CardComponent
              grid={4}
              title="Formas de Pagamento"
              first={<BudgetList first={resumo_estatisticas.formas_de_pagamento} />}
              icon={<BudgetList style={{ fontSize: "5px", }}/>}
            />

            <CardComponent
              grid={4}
              title="Bandeiras mais utilizadas"
              first={<BudgetList first={resumo_estatisticas.bandeiras_utilizadas} />}
            />        
            <CardComponent
              grid={4}
              title="Transações recusadas"
              first={resumo_estatisticas.transacoes_recusadas}
              icon={<FeatherIcon.CornerLeftDown size={25} color="red" />}
            />          
          </Grid>

          <Budget
            style={{ border: "none", boxShadow: "0px 0px 9px 2px #e0e0e0" }}
            grid={3}
            title="Transações por status"
          >
            <Tooltip title="Número de registros">
              <div style={{ width: "100%" }}>
                <BudgetChart data={resumo_estatisticas.transacoes_por_status} />
                <div style={{ fontSize: "14px" }}>
                  <InsertLegenda
                    data={resumo_estatisticas.transacoes_por_status}
                  />
                </div>
              </div>
            </Tooltip>
          </Budget>

          <Budget
            grid={6}
            style={{ border: "none", boxShadow: "0px 0px 9px 2px #e0e0e0" }}
            title="Quantidade de parcelas no cartão de crédito"
          >
            <BarChartPgto data={resumo_estatisticas.quantidade_parcelas_cc} />
          </Budget>

          <Budget
            grid={6}
            container
            style={{ border: "none", boxShadow: "0px 0px 9px 2px #e0e0e0" }}
            title="Índices de conversão"
          >
            <Grid item sm={4}>
              <BudgetPieChart
                data={resumo_estatisticas.indices_conversao.indice_real}
              />
            </Grid>
            <Grid item sm={4}>
              <BudgetPieChart
                data={
                  resumo_estatisticas.indices_conversao.indice_sem_retentativas
                }
              />
            </Grid>
            <Grid item sm={4}>
              <BudgetPieChart
                data={resumo_estatisticas.indices_conversao.boleto}
              />
            </Grid>
          </Budget>

          <Budget
            grid={12}
            style={{ border: "none", boxShadow: "0px 0px 9px 2px #e0e0e0" }}
            container
            title="Volume por dia da semana"
          >
            <BudgetCardinalDays data={resumo_estatisticas.volume_dia_semana} />
          </Budget>
        </Grid>
      </Portlet>
    </div>
  );
};

export default Resumo;
