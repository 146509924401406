import React from 'react'
import {Grid, Paper} from '@material-ui/core'
import {makeStyles} from '@material-ui/styles'

const useStyles = makeStyles({
    root: {
        padding: 10
    }
})

const MiniBudget = (props) => {

    const classes = useStyles()

    return (
        <Grid sm={props.size || 3} className={classes.root} >
            <Paper elevation={1} className={classes.root}>
                <p style={{color: '#373738', fontWeight: 'bold', fontSize: '11px'}}>{props.title}</p>
                <h4 style={{marginTop: '0.2em', fontWeight: 'normal'}}>{props.text}</h4>
            </Paper>
        </Grid>
    )

}

export default MiniBudget