import React, { useState } from "react";
import {
  Link as RouterLink,
  useNavigate,
  Outlet,
  Link,
} from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import {  AppBar,  Popover,  Box,  Hidden,  IconButton,  Toolbar,  makeStyles,  Button,  Tooltip,  Divider,} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import InputIcon from "@material-ui/icons/Input";
import { useSelector, useDispatch } from "react-redux";

import logo from "../../assets/images/conexopay-logo.png";
import { AccountCircle, KeyboardArrowDown } from "@material-ui/icons";

const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    width: 60,
    height: 60,
  },
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();
  const [notifications] = useState([]);
  const navigate = useNavigate();

  function handleSignOut() {
    localStorage.removeItem("token");
    navigate("/login");
  }

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  function Profile() {
    return <></>;
  }

  const { width, usuario } = useSelector((state) => state.sidebar);

  return (
    <AppBar
      className={clsx(classes.root, className)}
      color="inherit"
      elevation={0}
      {...rest}
    >
      <Toolbar>
        <img
          src={logo}
          alt="logo"
          style={{ width: "11%", height: "auto", marginTop: "0.5em" }}
        />

        <Box flexGrow={1} />
        <Tooltip title="Meu Perfil">
          <Button onClick={handleClick} style={{color: '#595959'}}>
            <AccountCircle />
            &nbsp;{usuario.nome}  <KeyboardArrowDown/>
          </Button>
        </Tooltip>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <div style={{ padding: "1em" }}>
            <h5 style={{ color: "#58A0B5" }}>{usuario.nome} </h5>
            <h6 style={{ fontWeight: "normal", color: "gray", marginBottom: "1em", }} >
              {usuario.email}
            </h6>

            <Divider />
            <h6 style={{ fontWeight: "normal", color: "gray", marginTop: "1em" }} >
              Minha Conta
            </h6>
            <div style={{marginLeft: '6px', paddingRight: '4em', lineHeight: '1.7em', }}>
              <Link to="/dashboard/cadastro" style={{color: '#343d3f'}}> Dados cadastrais </Link><br/>
              <Link to="/dashboard/contas_bancarias" style={{color: '#343d3f'}}> Dados bancários </Link><br/>
              <Link to="/dashboard/senha" style={{color: '#343d3f'}}> Alterar senha </Link>
            </div>
            <h6 style={{ fontWeight: "normal", color: "gray", marginTop: "1em" }} >
              Geral
            </h6>
            <div style={{marginLeft: '6px', lineHeight: '1.7em'}}>
            <Link to="/" style={{color: '#343d3f'}}> Sair </Link>
            </div>
          </div>
        </Popover>
        <RouterLink to="/">
          <Tooltip title="Sair">
            <Button onClick={handleSignOut}>
              <InputIcon />
            </Button>
          </Tooltip>
        </RouterLink>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onMobileNavOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
      <div
        style={{
          height: "3px",
          position: "absolute",
          width: "100%",
          bottom: "0",
          left: "0",
          backgroundImage: `linear-gradient(to right, #f9fdff, #43a0cc)`,
        }}
      ></div>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func,
};

export default TopBar;
