import { Autocomplete } from '@material-ui/lab';
import { makeStyles } from '@material-ui/styles';
 
const useStyles = makeStyles({
  root: {
    backgroundColor: '#FCFCFC',
    height: '100vh'
  },
  grid: {
    height: '100%'
  },
  quoteWrapper: {
  },
  quote: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#1A718C',
  },
  quoteInner: {
    textAlign: 'center',
    flexBasis: '600px'
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: 30,
    paddingBototm: 5,
    paddingLeft: 5,
    paddingRight: 5
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
  },
  form: {
    paddingLeft: '100px',
    paddingRight: '100px',
    paddingBottom: '125px',
    flexBasis: '700px',
  },
  title: {
    marginTop: 30
  },
  fields: {
    marginTop: 10
  },
  textField: {
    width: '100%',
    '& + & ': {
      marginTop: 10
    }
  },
  signUp: {
    margin: 'auto',
    color: 'primary',
    '&:hover': {
        fontWeight: 'bold',
        textDecoration: 'none',
        cursor: 'pointer',
    }
  },
});

export default useStyles