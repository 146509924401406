import React from "react";
import { Portlet } from "../../components";
import useStyles from './styles'

function AlterarSenha() {

    const classes = useStyles()

  return (
    <div className={classes.root}>        
    <Portlet className={classes.titleMT}>
      Alterar Senha
      </Portlet>
    </div>
  );
}

export default AlterarSenha;
