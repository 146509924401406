import * as types from '../types/sidebar'
import queryString from 'query-string'
import http from '../../services/http'
import store from '../index'

export const getInfos = () => {
    return dispatch => {
        http.get(`/api/v1/adm/sidebar/infos`).then(
            resp=>{
                let dados = resp.data
                dispatch([
                    {type: types.USER_NAME, payload: dados}
                ])
            },
            error=>{
                console.log(error)
            }
        )
    }
}