import { makeStyles } from '@material-ui/styles';
import theme from '../../theme';

const drawerWidth = 240;

const useStyles = makeStyles({
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        marginTop: 56,
        ...theme.mixins.toolbar,
        justifyContent: 'flex-start',
      },
      drawerPaper: {
        width: drawerWidth,
      },  drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
      },  
      drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
      drawerClose: {
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: 50
      },
})

export default useStyles;