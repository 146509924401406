import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import useStyles from "./styles";
import { Portlet } from "../../components";
import Dados from './components/dados'
import Confirmacao from './components/confirmacao'
import Conclusao from './components/conclusao'
import { loadContasBancarias } from './../../store/actions/contas_bancarias';
import dayjs from 'dayjs'

const Saque = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();



  useEffect(() => {
    dispatch(loadContasBancarias());
  }, []);

  const [step, setStep] = useState(1);

  const [modals, setModals] = useState({ calendario: false });

  const nextStep = () => {
    setStep(step + 1);
  };
  const prevStep = () => {
    setStep(step - 1);
  };
  const initialStep = () => {
    setStep(step - 3);
  };

  useEffect(() => {
    dispatch(loadContasBancarias());
  }, []);

  const hoje = new Date()

 const data_solicitacao = dayjs(hoje).format('DD/MM/YYYY')

 console.log(data_solicitacao)

  const [valorSaque, setValorSaque] = useState({ saque: '', taxa: 1.35});  


  switch (step) {
    case 1:
      return (
        <div className={classes.root}>
          <Portlet className={classes.titleMT}>
            <Dados
            valor={valorSaque}
            date={data_solicitacao}
            handleChange={setValorSaque}
            next={nextStep}/>
            </Portlet>
        </div>
      );
    case 2:
      return(
        <div className={classes.root}>
        <Portlet className={classes.titleMT}>
            <Confirmacao date={data_solicitacao} valor={valorSaque} next={nextStep} prev={prevStep}/>
            </Portlet>
      </div>
      );
    case 3:
      return (
        <div className={classes.root}>
        <Portlet className={classes.titleMT}>  <Conclusao data={data_solicitacao}  date={data_solicitacao} valor={valorSaque} next={nextStep} prev={prevStep}/></Portlet>
      </div>
      );
    case 4:
      return (
        <div className={classes.root}>
        <Portlet className={classes.titleMT}>4</Portlet>
      </div>
      );
    default:
      return "";
  }

  // return (
  //   <div className={classes.root}>
  //     <Portlet className={classes.titleMT}>
  //       {/* {switch (step){
  //         case 1:
  //           return
  //       }} */}
  //     </Portlet>
  //   </div>
  // );
};

export default Saque;
