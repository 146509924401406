import React from "react";
import {Box} from '@material-ui/core';
import {TextField} from '@material-ui/core';
import {InputAdornment} from '@material-ui/core';

import {  Search as SearchIcon } from "@material-ui/icons";


function InputSearch({ label, placeholder, value, disabled = false, onChange, onClick, onSearchClick, sx, children, variant }) {
  return (
    <Box>
      <TextField
        fullWidth
        label={label}
        placeholder={placeholder}
        value={value}
        disabled={disabled}
        onChange={onChange}
        // onClick={onClick}
        sx={sx}
        margin="dense"
        InputLabelProps={{ shrink: true }}
        variant={variant || 'outlined'}
        InputProps={{
          endAdornment: (
            <InputAdornment onClick={onSearchClick} position="end" sx={onSearchClick && {cursor:'pointer'}}>
              <SearchIcon style={{height: '24px', width: '24px'}} />
            </InputAdornment>
          ),
        }}
      />
      {children}
    </Box>
  );
}

export default InputSearch;