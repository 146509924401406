import { Grid, Paper, Icon, Divider } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Portlet, RSHeaderBar, Dialog } from "../../components";
import useStyles from "./components/styleVisualizar";
import { useSelector, useDispatch } from "react-redux";
import {
  visualizarTransacao,
  estornarTransacao,
} from "../../store/actions/transacao";

import Swal from "sweetalert2";

import dayjs from "dayjs";
import MiniBudget from "./components/miniBudget";
import AccordionComponent from "./components/AccordionComponent";
import {
  CheckCircle,
  MinusCircle,
  PauseCircle,
  PlusCircle,
  XCircle,
} from "react-feather";
import { AccountCircle, Receipt } from "@material-ui/icons";

const Visualizar = () => {
  const { id: params_id } = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [att, setAtt] = useState(1);

  const Toast = Swal.mixin({
    toast: true,
    position: "bottom-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const {
    loading: loading_transacao,
    transaction_selected: transaction,
    transaction_cli_selected: cli,
    transaction_history_selected: historico,
  } = useSelector((state) => state.transacao);

  function loadTransaction() {
    dispatch(visualizarTransacao(params_id));
  }

  useEffect(() => {
    loadTransaction();
  }, []);

  const toCurrency = (value) => {
    let reais = new Intl.NumberFormat("pt-BR", {
      currency: "BRL",
      style: "currency",
    }).format(value);
    return reais;
  };

  async function sendEstorno() {
    try {
      await estornarTransacao(transaction.numero_comprovante);
      Toast.fire({
        icon: "success",
        title: "Estorno Realizado com Sucesso!",
      });
      setDialogCancelarTransacao(false);
    } catch (error) {
      Toast.fire({
        icon: "error",
        title:
          "Não foi possível realizar o estorno, data limite não permitida !",
      });
      setDialogCancelarTransacao(false);
    }
  }

  const [dialogCancelarTransacao, setDialogCancelarTransacao] = useState(false);

  const longText =
    "O custo de processamento não será somado ao total de saídas dessa Transação. Ele será debitado ao final do mês, junto aos custos de processamento das demais transações do período";

  return (
    <div className={classes.root}>
      <Portlet>
        <RSHeaderBar
          title={`Detalhe da Transação: ${params_id}`}
          buttons={[
            {
              danger: false,
              title: "Voltar",
              onClick: () => {
                window.history.back();
              },
            },
          ]}
        />
        <Grid container spacing={1}>
          <Grid container sm={10} xs={10}>
            <Grid container spacing={1} style={{marginLeft: '3em'}}>
              <Grid sm={1} className={classes.stylePaper}>
                <div className={classes.titleVis}>
                  <strong>Transação:</strong>
                </div>
                <div className={classes.styleStatusCheck}>
                  <b>#</b>
                  {`${transaction.id}`}
                </div>
              </Grid>

              <Grid sm={2} className={classes.stylePaper}>
                <div className={classes.titleVis}>
                  <strong>Status:</strong>
                </div>
                <div>
                  {transaction.status == "P" ? (
                    <div className={classes.styleStatusCheck}>
                      <PauseCircle color="#235894" size="16" /> Pendente
                    </div>
                  ) : transaction.status == "N" ? (
                    <div className={classes.styleStatusCheck}>
                      <MinusCircle color="#DF285F" size="16" /> Negado
                    </div>
                  ) : transaction.status == "A" ? (
                    <div className={classes.styleStatusCheck}>
                      <CheckCircle color="#edc91a" size="16" /> Aprovado
                    </div>
                  ) : transaction.status == "F" ? (
                    <div className={classes.styleStatusCheck}>
                      <PlusCircle color="#23d143" size="16" /> Finalizado
                    </div>
                  ) : transaction.status == "E" ? (
                    "EXPIRADO"
                  ) : transaction.status == "C" ? (
                    <div className={classes.styleStatusCheck}>
                      <XCircle color="#660909" size="16" /> Cancelado
                    </div>
                  ) : (
                    "DESCONHECIDO"
                  )}
                </div>
              </Grid>

              <Grid sm={1} className={classes.stylePaper}>
                <div className={classes.titleVis}>
                  <strong>Pagamento:</strong>
                </div>
                <div className={classes.styleStatusCheck}>
                  {`${transaction.parcelas}X`}
                </div>
              </Grid>
              <Grid sm={3} className={classes.stylePaper}>
                <div className={classes.titleVis}>
                  <strong>Cartão de Crédito:</strong>
                </div>
                <div className={classes.styleStatusCheck}>
                  <Icon
                    style={{ fontSize: 16, color: "gray" }}
                    className="fas fa-credit-card"
                  />{" "}
                  &nbsp;{`${transaction.card_bin}`}
                </div>
              </Grid>
              <Grid sm={5} className={classes.stylePaper}>
                <div className={classes.titleVis}>
                  <strong>Titular:</strong>
                </div>
                <div className={classes.styleStatusCheck} style={{maxWidth: '20ch', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: "nowrap"}}>
                  &nbsp;{`${transaction.card_holder}`}
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid sm={2}>
            {transaction.status == "C" ? (
              " "
            ) : transaction.status == "N" ? "" : (
              <button
                className={classes.btnEstorno}
                style={{ marginTop: "5em" }}
                //   disabled={dayjs().diff(dayjs(transaction.data), "day") > 30 ? true : false }
                //   onClick={() => { estornarTransacao(transaction.numero_comprovante) }}
                onClick={() => setDialogCancelarTransacao(true)}
              >
                <Icon
                  className="fas fa-undo"
                  style={{ fontSize: 12, marginRight: 5 }}
                />{" "}
                Estornar esta Compra
              </button>
            )}
            {/* <Button variant="outlined" style={{ color: "green" }}>
             
              <Icon
                className="fas fa-file-download"
                style={{ fontSize: 12, marginRight: 5 }}
              />{" "}
              Exportar
            </Button> */}
          </Grid>
        </Grid>

        <Grid container spacing={1} style={{ marginBottom: "2em", marginLeft: '3em' }}>
          <Grid sm={3} className={classes.stylePaper}>
            <div className={classes.titleVis}>
              <strong>Valor capturado:</strong>
            </div>
            <div className={classes.styleStatusCheck}>
              &nbsp;{`${toCurrency(transaction.valor_bruto)}`}
            </div>
          </Grid>

          <Grid sm={3} className={classes.stylePaper}>
            <div className={classes.titleVis}>
              <strong>Total de saídas</strong>
            </div>
            <div className={classes.styleStatusCheck}>
              &nbsp;{`${toCurrency(transaction.valor_bruto)}`}
            </div>
          </Grid>

          <Grid sm={3} className={classes.stylePaper}>
            <div className={classes.titleVis}>
              <strong>Valor líquido</strong>
            </div>
            <div className={classes.styleStatusCheck}>
              &nbsp;{`${toCurrency(transaction.valor_liquido)}`}
            </div>
          </Grid>

          {/* <Budget grid={3} container xs={3} title="Cartão de crédito">
            <Grid container sm={12}>
              <Grid sm={12} style={{ textAlign: "left" }}>
                <Typography variant="h2">{`${transaction.card_bin}`}</Typography>
              </Grid>
              <Grid
                sm={12}
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                style={{ textAlign: "left", fontWeight: 400 }}
              >
                <Grid item sm={10} style={{ marginTop: 15 }}>
                  <span
                    style={{ fontSize: 16 }}
                  >{`${transaction.card_holder}`}</span>
                </Grid>

                <Grid item sm={2} style={{ marginTop: 15 }}>
                  <Icon
                    style={{ fontSize: 16 }}
                    className="fas fa-credit-card"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Budget>

          <Budget grid={3} title="Valor capturado">
            <Typography variant="h3">{`${toCurrency(
              transaction.valor_bruto
            )}`}</Typography>
          </Budget>

          <Budget grid={3} container title="Total de saídas">
            <Grid item sm={12} style={{ textAlign: "left" }}>
              <Typography variant="h3">{`R$ 0,00`}</Typography>
            </Grid>

            <Grid
              item
              container
              sm={12}
              style={{ textAlign: "left", fontSize: 14, fontWeight: 400 }}
            >
              <Grid sm={6}>
                <p style={{ marginTop: 15 }}>{`MDR: R$ 0,00`}</p>
                <p>{`Custo de processamento: R$ 3,65`}</p>
              </Grid>

              <Grid
                sm={1}
                direction="row"
                justify="flex-start"
                alignItems="flex-end"
              >
                <div>
                  <Tooltip title={longText}>
                    <Icon
                      fontSize="medium"
                      style={{ marginLeft: 5, marginTop: 20 }}
                      className="far fa-question-circle"
                    />
                  </Tooltip>
                </div>
              </Grid>
            </Grid>
          </Budget>

          <Budget grid={3} title="Valor líquido">
            <Typography variant="h3">{`${
              toCurrency(transaction.valor_liquido) || "R$ 0,00"
            }`}</Typography>
          </Budget> */}
        </Grid>

        <Grid container sm={12}>
          <Grid container sm={9}>
            <Paper square elevation={3} style={{ marginTop: 10 }}>
              <Grid container sm={12}>
                <div style={{
                  marginLeft: '2em', 
                  marginTop: '1em', 
                  display: 'flex', 
                  flexDirection: 'row', 
                  justifyContent: 'center', 
                  alignItems: 'center',
                  color: '#424242',
                  marginBottom: '1.2em',
                   }}>
                 <AccountCircle/>&nbsp;<h4>Detalhes do Cliente</h4></div>
              </Grid>
              <Grid container sm={12}>
                <MiniBudget
                  size={6}
                  title={`Nome do cliente`}
                  text={`${transaction.card_holder || "Não Informado"}`}
                />
                <MiniBudget
                  size={3}
                  title={`CPF/CNPJ`}
                  text={`${cli !== undefined ? cli.cpf_cnpj : "Não Informado"}`}
                />
                <MiniBudget
                  size={3}
                  title={`Data de Nascimento`}
                  text={`${
                    cli !== undefined ? cli.data_nasc : "Não Informado"
                  }`}
                />
                <MiniBudget
                  size={3}
                  title={`Telefone`}
                  text={`${cli !== undefined ? cli.telefone : "Não Informado"}`}
                />
                <MiniBudget
                  size={3}
                  title={`E-mail`}
                  text={`${cli !== undefined ? cli.email : "Não Informado"}`}
                />
                <MiniBudget
                  size={4}
                  title={`Rua`}
                  text={`${cli !== undefined ? cli.endereco : "Não Informado"}`}
                />
                <MiniBudget
                  size={2}
                  title={`Número`}
                  text={`${cli !== undefined ? cli.numero : "Não Informado"}`}
                />
                <MiniBudget
                  size={2}
                  title={`Complemento`}
                  text={`${
                    cli !== undefined ? cli.complemento : "Não Informado"
                  }`}
                />
                <MiniBudget
                  size={3}
                  title={`Bairro`}
                  text={`${cli !== undefined ? cli.bairro : "Não Informado"}`}
                />
                <MiniBudget
                  size={3}
                  title={`Cidade`}
                  text={`${cli !== undefined ? cli.cidade : "Não Informado"}`}
                />
                <MiniBudget
                  size={2}
                  title={`Estado`}
                  text={`${cli !== undefined ? cli.uf : "Não Informado"}`}
                />
                <MiniBudget
                  size={2}
                  title={`CEP`}
                  text={`${cli !== undefined ? cli.cep : "Não Informado"}`}
                />
              </Grid>
       
           <Divider />
              <Grid container sm={12}>
                <div style={{
                  marginLeft: '2em', 
                  marginTop: '3em', 
                  display: 'flex', 
                  flexDirection: 'row', 
                  justifyContent: 'center', 
                  alignItems: 'center', 
                  color: '#424242' ,
                  marginBottom: '1.2em',
                  }}>
                    <Receipt />&nbsp;<h4>Detalhes da transação</h4>
                 </div>
              </Grid>
              <Grid container sm={12}>
                <MiniBudget
                  size={3}
                  title={`Operadora do cartão`}
                  text={`${transaction.bandeira || "Não Informado"}`}
                />
                <MiniBudget
                  size={3}
                  title={`Resposta da operadora`}
                  text={`${
                    transaction.status == "N"
                      ? "Negado"
                      : transaction.status == "A"
                      ? "Aprovado"
                      : transaction.status == "C"
                      ? "Cancelado"
                      : transaction.status == "P"
                      ? "Pendente"
                      : "Não Informado"
                  }`}
                />
                <MiniBudget
                  size={3}
                  title={`Código de autorização`}
                  text={`${transaction.numero_comprovante || "Não informado"}`}
                />
                <MiniBudget
                  size={3}
                  title={`Método de captura`}
                  text={`${transaction.modelo_terminal || "Não Informado"}`}
                />
                <MiniBudget
                  size={3}
                  title={`NSU`}
                  text={`${transaction.numero_autorizacao || "Não informado"}`}
                />
                <MiniBudget
                  size={3}
                  title={`Descrição na fatura`}
                  text={`CXPAY`}
                />
                <MiniBudget
                  size={3}
                  title={`Id da assinatura`}
                  text={`${transaction.credenciado_id || "Não Informado"}`}
                />
                <MiniBudget
                  size={3}
                  title={`TID (ID da transação)`}
                  text={`${params_id || "Não Informado"}`}
                />
              </Grid>
     
            </Paper>

            {/* <Paper square elevation={3} style={{ marginTop: 20 }}>
              <Grid container sm={12}>
                <RSHeaderBar title="Detalhes da transação" buttons={[]} />
              </Grid>
              <Grid container sm={12}>
                <MiniBudget
                  size={3}
                  title={`Operadora do cartão`}
                  text={`${transaction.bandeira || "Não Informado"}`}
                />
                <MiniBudget
                  size={3}
                  title={`Resposta da operadora`}
                  text={`${
                    transaction.status == "N"
                      ? "Negado"
                      : transaction.status == "A"
                      ? "Aprovado"
                      : transaction.status == "C"
                      ? "Cancelado"
                      : transaction.status == "P"
                      ? "Pendente"
                      : "Não Informado"
                  }`}
                />
                <MiniBudget
                  size={3}
                  title={`Código de autorização`}
                  text={`${transaction.numero_comprovante || "Não informado"}`}
                />
                <MiniBudget
                  size={3}
                  title={`Método de captura`}
                  text={`${transaction.modelo_terminal || "Não Informado"}`}
                />
                <MiniBudget
                  size={3}
                  title={`NSU`}
                  text={`${transaction.numero_autorizacao || "Não informado"}`}
                />
                <MiniBudget
                  size={3}
                  title={`Descrição na fatura`}
                  text={`CXPAY`}
                />
                <MiniBudget
                  size={3}
                  title={`Id da assinatura`}
                  text={`${transaction.credenciado_id || "Não Informado"}`}
                />
                <MiniBudget
                  size={3}
                  title={`TID (ID da transação)`}
                  text={`${params_id || "Não Informado"}`}
                />
              </Grid>
            </Paper> */}
          </Grid>

          <Grid container sm={3}>
            <Grid sm={12}>
              <Paper square elevation={3} style={{ marginTop: 20 }}>
                <Grid sm={12}>
                  <RSHeaderBar title="Histórico da transação" buttons={[]} />
                </Grid>

                <Grid sm={12}>
                  <AccordionComponent dados={historico} />
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Portlet>
      <Dialog
        open={dialogCancelarTransacao}
        title="Tem certeza que deseja estornar esta compra?"
        contenttext={
          <>
            Você está prestes a estornar <b>1</b> transação no valor de{" "}
            <b>{toCurrency(transaction.valor_bruto)}</b>. <br />
            <br />
            Serviços podem ser cancelados e acessos bloqueados após esta ação.{" "}
            <br />
            <br />
            Esta operação não pode ser revertida.{" "}
          </>
        }
        buttons={[
          {
            title: "CANCELAR",
            style: {
              color: "gray",
              backgroundColor: "#fff",
              border: "1px solid gray",
            },
            onClick: () => {
              setDialogCancelarTransacao(false);
            },
          },
          {
            title: "ESTORNAR",
            style: { color: "#fff", backgroundColor: "#FE4D6F" },
            onClick: () => {
              sendEstorno();
            },
          },
        ]}
      />
    </div>
  );
};

export default Visualizar;
