import { Grid } from '@material-ui/core';
import React, {} from 'react';
import { PieChart, Pie, Cell } from 'recharts';


const BudgetPieChart = props => {
    // const percent = 100
    // const titulo = 200
    // const dados = [
    //     { name: 'Tru', value: 4 },
    //     { name: 'Farso', value: 34 },
    // ];

    const COLORS = [ '#CCC', '#2BB2D1'];
    const {titulo, percent, dados} = props.data

    return (
        <div style={{textAlign: 'center', fontSize: 18, color: 'gray', width: '100%'}}>
           
            <PieChart width={300} height={150}>
                <Pie
                    data={dados}
                    cx={100}
                    cy={65}
                    innerRadius={35}
                    outerRadius={40}
                    startAngle={180}
                    endAngle={0}
                    //fill="#8884d8"
                    paddingAngle={5}
                    dataKey="value"
                >
                    {dados.map((entry, index) => (
                        <Cell key={`${entry.name}`} fill={COLORS[index % COLORS.length]} />
                    ))}

                </Pie>
            </PieChart>
            <h5> {percent}%</h5>
            <span style={{fontSize: 15}}>{titulo}</span>
        </div>
    );

}

export default BudgetPieChart