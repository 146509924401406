import * as types from '../types/extrato'
import queryString from 'query-string'
import http from '../../services/http'
import store from '../index'


export const loadResumoExt = (filtro) => {
    let {
        ext_perpage: per_page,
        ext_page: page,
        dados_periodo,
    } = store.getState().extrato
    let daysResumo = store.getState().dashboard

    const qry = queryString.stringify({...daysResumo, per_page, page, ...filtro}, {arrayFormat: 'index'});
    return dispatch => {
        http.get(`/api/v1/adm/extrato/resumo?${qry}`).then(
            resp => {
                const {credenciado, conta_bancaria, saldo, aReceber, resumo, entradas, saidas, taxas_cxpay, liquido, newLx, pagination } = resp.data
               dispatch([
                   {type: types.CONTA_BANCARIA, payload: conta_bancaria},
                   {type: types.CREDENCIADO, payload: credenciado},
                   {type: types.SALDO, payload: saldo},
                   {type: types.A_RECEBER, payload: aReceber},
                   {type: types.DADOS_PERIODO, payload: {...dados_periodo, entradas, saidas, taxas_cxpay, liquido, lista_extrato: newLx}},
                   {type: types.SET_EXT_PAGE, payload: pagination.current_page},
                   {type: types.SET_EXT_PERPAGE, payload: pagination.per_page},
                   {type: types.SET_EXT_TOTAL, payload: pagination.total},
                   {type: types.EXT_LOADING, payload: false},
               ])
            },
            error => {
                console.log(error.data)

            }
        )
    }
}


export const loadAntecipaveis = () => {
    http.get(`/api/v1/adm/extrato/antecipaveis`).then(
        resp=>console.log(resp.data),
        error=>console.log({erro: error})
    )
}