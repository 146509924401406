import React, { useEffect, useState } from "react";
import { Link, Link as RouterLink, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import {  Avatar,  Box,  Button,  Divider,  Drawer,  Hidden,  List,  Typography,  makeStyles,} from "@material-ui/core";

import {
  Briefcase as BriefcaseIcon,
  BarChart as BarChartIcon,
  Calendar as CalendarIcon,
  Settings as SettingsIcon,
  ShoppingBag as ShoppingBagIcon,
  User as UserIcon,
  Home as HomeIcon,
  UserPlus as UserPlusIcon,
  Users as UsersIcon,
  FileText as FileTextIcon,
  TrendingUp as TrendingUpIcon,
} from "react-feather";

import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";

import NavItem from "./NavItem";
import * as types from "../../../store/types/sidebar";
import { getInfos } from "../../../store/actions/sidebar";
import { loadResumoExt } from "../../../store/actions/extrato";

import Saldo from "./Saldo";

const items = [
  {
    href: "/dashboard/resumo",
    icon: HomeIcon,
    title: "Resumo",
  },
  {
    href: "/dashboard/extrato",
    icon: FileTextIcon,
    title: "Extrato",
  },
  {
    href: "/dashboard/transacoes",
    icon: TrendingUpIcon,
    title: "Transações",
  },
];

const NavBar = ({ onMobileClose, openMobile }) => {
  const location = useLocation();
  const { width, usuario } = useSelector((state) => state.sidebar);
  const dispatch = useDispatch();

  // console.log(usuario)

  const { saldo } = useSelector((state) => state.extrato);

  const [saldoCarteira, setSaldoCarteira] = useState(false);

  function SaldodaCarteira() {
    setSaldoCarteira(saldoCarteira ? false : true);
  }

  const getInfoUsers = () => {
    dispatch(
      [getInfos(), 
      loadResumoExt()
    ],
      
      );
  };

  useEffect(() => {
    getInfoUsers();
  }, []);

 

  const useStyles = makeStyles(() => ({
    desktopDrawer: {
      width: 256,
      top: 64,
      height: "calc(100% - 64px)",
      transition: "all .3s",
      overflowX: "hidden",
    },
    avatar: {
      cursor: "pointer",
      width: 64,
      height: 64,
    },
    avatarIconDrawer: {
      cursor: "pointer",
      width: 40,
      height: 40,
      margin: "44px 0 44px 0",
    },
    carteira: {
      marginTop: "0.5em",
      textAlign: "center",
      "& button": {
        border: "none",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        backgroundColor: "transparent",
        cursor: "pointer",
        color: "#2C526F",
        fontWeight: "bold",
        fontFamily: "roboto",
        fontSize: "14px",
      },
      "& h5": {
        fontWeight: "normal",
      },
    },
    saque:{
      textAlign: 'center',
      marginTop: '10px',
      '& button':{
        marginTop: '11px',
        border: 'none',
        padding: '3px 7px',
        cursor: 'pointer',
        background: '#53A8D0',
        color: '#fff',
        borderRadius: '3px'
      }
    }
  }));

  const toCurrency = (value) => {
    let reais = new Intl.NumberFormat("pt-BR", {
      currency: "BRL",
      style: "currency",
    }).format(value);
    return reais;
  };

  const classes = useStyles();

  const content = (isMobile) => (
    <Box height="100%" display="flex" flexDirection="column">
      <Box alignItems="center" display="flex" flexDirection="column" p={2}>
        <Typography className={classes.name} color="textPrimary" variant="h5">
          {usuario.nome}
        </Typography>
        <Typography color="textSecondary" variant="body2">
          Credenciado
        </Typography>
        <div className={classes.carteira}>
          <button onClick={SaldodaCarteira}>
            {saldoCarteira ? (
              <>
                Ocutar Saldo <ArrowDropUp />{" "}
              </>
            ) : (
              <>
                Mostrar Saldo <ArrowDropDown />
              </>
            )}
          </button>
        </div>
        <div className={classes.saque}>
          {saldoCarteira ? (
            <>
              <h5 style={{fontWeight: 'normal', color: '#2c526f'}}>Disponível para saque</h5>
              <b>{toCurrency(saldo.toFixed(2)) || "R$ 0.00"}</b><br/>
              <Link to="/dashboard/saque">
              <button> Sacar </button>
              </Link>
            </>
          ) : (
            ""
          )}
        </div>
      </Box>
      <Divider />
      <Box p={2}>
        <List>
          {items.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
        </List>
      </Box>
      <Box flexGrow={1} />
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content(true)}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content(false)}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: true,
};

export default NavBar;
