import React, { PureComponent } from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';


const GrafTrans = (props) => {
    const data = props.dados
    let fullStats = []

    data.map( (row, index) => {

        let legenda = row.status == 'P'? 'Pendente'
                        : row.status == 'N'? 'Negada'
                        : row.status == 'A'? 'Aprovada'
                        : row.status == 'F'? 'Finalizada'
                        : row.status == 'E'? 'Expirada'
                        : row.status == 'C'? 'Cancelada'
                        : 'Desconhecida'

        if(fullStats.find( sts => sts.status == row.status)){
            fullStats.forEach( line => {
                if(line.status == row.status){
                    line.count += 1
                }
            })
        }else{
            fullStats.push({legenda,status: row.status, count: 1})
        }
    })

    return (
      <ResponsiveContainer width={100+'%'} height={300}>
        <BarChart width={150} height={40} data={fullStats}>
            <CartesianGrid strokeDasharray= "7 7"/>
            <Tooltip />
            <XAxis dataKey="legenda" />
            <YAxis />
          <Bar dataKey="count" fill="blue" label={{fill: 'white'}} barSize={30} />
        </BarChart>
      </ResponsiveContainer>
    )
}

export default GrafTrans