import React, {} from 'react'
import {
    Grid,
    Button,
    Card,
    CardActions,
    CardContent,
    Typography,
    Avatar,
    Icon,
    Tooltip
} from '@material-ui/core'
import {makeStyles} from '@material-ui/styles'
import {RSNumberFormat} from '../../../components'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

import * as FeatherIcon from 'react-feather'

const useStyles = makeStyles({
    root: {
      minWidth: 275,
      minHeight: 180
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    centralizar: {
      textAlign: 'center'
    },
    mtop: {
      marginTop: '10%',
    },
    customWidth: {
        maxWidth: 500,
        fontSize: 14
    }
  });

const Budget = (props) => {
    const classes = useStyles();
    let letraColor = (props.titleColor)?props.titleColor:'black'
    return (
        <Grid item sm={props.grid || 3} >
            <Card className={classes.root}   style={props.style} variant="outlined">
                <CardContent className={classes.centralizar}>
                    
                    <div style={{display: 'flex', justifyContent: 'center', marginTop: 10}}>
                        <Typography className={classes.title} style={{color: letraColor, fontSize: 16}} gutterBottom>
                            {props.title} 
                        </Typography>
                        <Tooltip title={props.toolTipTitle} classes={{tooltip: classes.customWidth}}>
                            <Icon fontSize="small" style={{color: letraColor, marginLeft: 5}} className="far fa-question-circle"/>
                        </Tooltip>
                    </div>

                    <Typography variant="h3" component="h2">
                        <Grid container className={classes.mtop} sm={12} justify="center">
                         {props.children}
                        </Grid>
                    </Typography>


                </CardContent>
            </Card>
        </Grid>
    );
}

export default Budget;