export const LOAD_USUARIO_CREDENCIADOS = 'LOAD_USUARIO_CREDENCIADOS';
export const LOAD_USUARIO_ATUAL = 'LOAD_USUARIO_ATUAL';
export const LOAD_USUARIO_CONFIG = 'LOAD_USUARIO_CONFIG';
export const SET_MODAL_USUARIO_CREDENCIADOS = 'SET_MODAL_USUARIO_CREDENCIADOS';
export const SET_EMPRESA_ATUAL = 'SET_EMPRESA_ATUAL';
export const SET_USUARIO_LOADING = 'SET_USUARIO_LOADING';
export const LOAD_USUARIO_LISTA = 'LOAD_USUARIO_LISTA';
export const SET_USUARIO_LISTA_PAGE = 'SET_USUARIO_LISTA_PAGE';
export const SET_USUARIO_LISTA_PERPAGE = 'SET_USUARIO_LISTA_PERPAGE';
export const SET_USUARIO_LISTA_TOTAL = 'SET_USUARIO_LISTA_TOTAL';
export const SET_USUARIO_LISTA_FILTER = 'SET_USUARIO_LISTA_FILTER';