import * as types from '../types/saque'
import queryString from 'query-string'
import http from '../../services/http'
import store from '../index'

export const getInfos = () => {
    return dispatch => {
        http.get(`/api/v1/adm/sidebar/infos`).then(
            resp=>{
                let dados = resp.data
                dispatch([
                    {type: types.SAQUE_USUARIO_SET, payload: dados.email}
                ])
            },
            error=>{
                console.log(error)
            }
        )
    }
}

export const validarSenha = async (email, senha) => {
    return new Promise((resolve, reject) => {
      store.dispatch({ type: types.SAQUE_LOADING, payload: true });
      http.post(`/api/v1/adm/usuario/autenticar`, { email, senha }).then(
        ({ data }) => {       
          resolve();
        },
        (e) => {
          store.dispatch({ type: types.SAQUE_LOADING, payload: false });
          reject();
        }
      );
    });
  };


export const saqueValores = async (valor) => {


    return new Promise((resolve, reject) => {
      store.dispatch({ type: types.SAQUE_LOADING, payload: true });
      http.post(`/api/v1/adm/movimentacoes/transferencia`, { valor, tipo: 'TR' }).then(
        ({ data }) => {       
          resolve();
        },
        (e) => {
          store.dispatch({ type: types.SAQUE_LOADING, payload: false });
          reject();
        }
      );
    });
  };