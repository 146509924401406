import React, {} from 'react'
import {
    Grid,
    Button,
    Card,
    CardActions,
    CardContent,
    Typography,
    Avatar,
    Icon,
    Tooltip
} from '@material-ui/core'
import {makeStyles} from '@material-ui/styles'
import {RSNumberFormat} from '../../../components'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

import * as FeatherIcon from 'react-feather'

const useStyles = makeStyles({
    root: {
      // minWidth: 275,
      // minHeight: 180
    },
    bullet: {
      // display: 'inline-block',
      // margin: '0 2px',
      // transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    centralizar: {
      textAlign: 'center'
    },
    mtop: {
      marginTop: '10%',
      marginBottom: '1.5em'
    },
  });

const Budget = (props) => {
    const classes = useStyles();
    return (
        <Grid item sm={props.grid || 3} >
               <div className={classes.root} style={props.style} variant="outlined">
                <CardContent>
                    
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                        {props.title}
                    </Typography>

                    <Typography variant="h3" component="h2">
                        <Grid container className={classes.mtop} sm={12} justify="space-between">
                         {props.children}
                         {props.icon}
                        </Grid>
                    </Typography>


                </CardContent>
            </div>
        </Grid>
    );
}

export default Budget;