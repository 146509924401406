import React from "react";
import {
  Grid,
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
  Avatar,
  Icon,
  Tooltip,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { RSNumberFormat } from "../../../components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CreditCard as CreditCardIcon } from "@material-ui/icons";

import * as FeatherIcon from "react-feather";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 12,
  },
  pos: {
    marginBottom: 10,
  },
  centralizar: {
    textAlign: "center",
  },
  mtop: {
    marginTop: "10%",
  },
  iconCredit: {
    position: "relative",
    top: 4,
    marginRight: 5,
  },
  text: {
    marginTop: 10,
    fontSize: 14,
  },
});

const BudgetList = (props) => {
  const classes = useStyles();
  const first = props.first;
  const second = props.second;
  return (
    <>
      <TableContainer>
        <Table>
       
          <TableBody >
            {first?.map((item) => (              
              <TableRow key={item.tipo} >
                <TableCell  scope="row" align="left"  style={{ borderBottom: "none", maxWidth: '10ch', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', cursor: 'pointer'}}>
                  {" "}
                  <CreditCardIcon className={classes.iconCredit} /> {item.tipo}{" "}
                </TableCell>
                <TableCell  scope="row"  style={{ borderBottom: "none"}}>
                  {" "}
                  {item.percent} %{" "}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* {first?.map((entry, index) => (
      <Grid
        item
        container
        sm={12}
        direction="row"
        justify="space-between"
        alignItems="baseline"
        className={classes.text}
      >

          <Grid item>
            <CreditCardIcon className={classes.iconCredit} />
            <span>
              {entry.tipo}
            </span>
          </Grid>       


      </Grid> */}
      {/* ))} */}
      {/* {second?.map((entry, index) => (
      <Grid
        item
        container
        sm={12}
        direction="row"
        justify="space-between"
        alignItems="baseline"
        className={classes.text}
       
      >       

          <Grid item>
          {entry.percent} %
          </Grid> 


      </Grid> */}
    </>
  );
};

export default BudgetList;
