import http from '../../services/http'
import * as types from './../types/conta_bancaria';
import queryString from 'query-string';

export const loadContasBancarias = filtro => {
  const qry = queryString.stringify(filtro, { arrayFormat: 'index' });
  return dispatch => {
    dispatch({ type: types.LOADING_CONTA_BANCARIA, payload: true });
    http.get(`/api/v1/adm/contas/listar?${qry}`).then(
      response => {
        const { total, current_page: page, per_page, data } = response.data;
        dispatch([
          { type: types.LOAD_CONTA_BANCARIA, payload: data },
          { type: types.LOAD_CONTA_BANCARIA_PAGE, payload: page },
          { type: types.LOAD_CONTA_BANCARIA_PERPAGE, payload: per_page },
          { type: types.LOAD_CONTA_BANCARIA_TOTAL, payload: total },
          { type: types.LOADING_CONTA_BANCARIA, payload: false }
        ]);
      },
      error => {
        dispatch({ type: types.LOADING_CONTA_BANCARIA, payload: false });
      }
    );
  };
};