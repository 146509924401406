import React from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { curveCardinal, curveLinearClosed } from "d3-shape";
import { Grid } from "@material-ui/core";

const BudgetCardinalDays = props => {
//   const data = [
//     {
//       name: "Page A",
//       value: 10000
//     },
//     {
//       name: "Page B",
//       value: 3000
//     },
//   ];

    const data = props.data

  const cardinal = curveLinearClosed(1)

  return (
    <Grid item sm={12} >
      <div
          style={{
        
            width: '60%',
            margin: 'auto'

          }}>
      <ResponsiveContainer width={700} height={200} >
        <AreaChart
          // width={500}
          // height={400}
          data={data}
      
          // margin={{
          //   top: 10,
          //   right: 30,
          //   left: 0,
          //   bottom: 0,
          // }}
        >
          <CartesianGrid strokeDasharray="1 1" />
          <XAxis dataKey="name" style={{fontSize: 15}} />
          <YAxis style={{fontSize: 15}} />
          <Tooltip />
          <Area
            type="monotone"
            dataKey="value"
            stroke="#8884d8"
            fill="#8884d8"
            fillOpacity={0.3}
          />
        </AreaChart>
      </ResponsiveContainer>
      </div>
    </Grid>
  );
};
export default BudgetCardinalDays;
