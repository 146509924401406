import * as types from '../types/dashboard'
import dayjs from 'dayjs'

const INITIAL_STATE = {
    data_inicio: dayjs().subtract(30, 'days').format('YYYY-MM-DD'),
    data_fim: dayjs().format('YYYY-MM-DD'),

}

export default (state = INITIAL_STATE, action) => {
    switch(action.type){
      case types.SET_PERIODO_INICIO:
        return {...state, data_inicio: action.payload}
      case types.SET_PERIODO_FIM:
        return {...state, data_fim: action.payload}
      default:
        return state
    }
  }