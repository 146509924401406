import 'react-perfect-scrollbar/dist/css/styles.css';
import {useRoutes} from 'react-router-dom';
import {ThemeProvider} from '@material-ui/core';
import {Provider} from 'react-redux';
import GlobalStyles from './components/GlobalStyles';
import theme from './theme';
import routes from './routes';
import store from './store';
import {responseInterceptor, requestInterceptor } from './services/http/interceptors';

responseInterceptor(store)
requestInterceptor(store)

const App = () => {
  const routing = useRoutes(routes);

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        {routing}
      </ThemeProvider>
    </Provider>
  );
};

export default App;