import React, { useState,useEffect } from "react";
import { Portlet } from "../../components";
import useStyles from "./styles";
import { AccountBalance, CheckCircle, InfoRounded } from "@material-ui/icons";
import { Grid, Divider, Tooltip } from "@material-ui/core";
import PerfectScrollBar from "react-perfect-scrollbar";
import { useSelector, useDispatch } from "react-redux";
import DataTable from "./../../components/DataTable/index";
import Paginate from "./../../components/Paginate/index";
import * as types from './../../store/types/conta_bancaria'
import { loadContasBancarias } from './../../store/actions/contas_bancarias';
import CadastrodeConta from "./CadastrodeConta";
import http from "../../services/http";
import Swal from "sweetalert2";
import bancosdb from './bancos'

function DadosBancarios() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [conta, setConta] = useState(false);
  const { conta_bancaria, page, per_page, total, filtro  } = useSelector(state => state.conta_bancaria);


  useEffect(() => {
    dispatch(loadContasBancarias({ ...filtro, page: 1, per_page: 10 }));
  }, []);

 


  const Toast = Swal.mixin({
    toast: true,
    position: "bottom-end",
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });


  const handleChangePage = (event, newPage) => {
    dispatch([
      { type: types.LOADING_CONTA_BANCARIA, payload: true },
      { type: types.LOAD_CONTA_BANCARIA_PAGE, payload: newPage + 1 },
      loadContasBancarias({...filtro, page: newPage + 1, per_page, paginate: true, }),
    ]);
  }

  const handleChangeRowsPerPage = (event) => {
    dispatch([
      { type: types.LOADING_CONTA_BANCARIA, payload: true },
      { type: types.LOAD_CONTA_BANCARIA_PERPAGE, payload: true },
      loadContasBancarias({ ...filtro, page: 1, per_page , paginate: true,}),
    ]);
  };


  function atualizar (){
    setConta(false)
    dispatch(loadContasBancarias({ ...filtro, page: 1, per_page: 10 }))
  }



  const contaPrinciapl = (id_conta) => {
    return new Promise( (resolve, reject) => {
    http.put(`/api/v1/adm/contas/conta_principal/${id_conta}`).then(
      response => resolve(response.data),
      err => reject(err)
    )   
   })
  }

  async function update(conta_id) {  
    try {
      await contaPrinciapl(conta_id);   
      Toast.fire({
        icon: "success",
        title: "Conta Atualizada com sucesso!",
      });
      atualizar()
    } catch (error) {
      console.log('erro')
    }
  }

  return (
    <div className={classes.root}>
      <Portlet className={classes.titleMT}>
        <div
          style={{ display: "flex", flexDirection: "row", padding: "1.2em" }}
        >
          <AccountBalance /> &nbsp; Dados Bancarios
        </div>
        <Divider />
        <div>
          <button className={classes.bntSelect} style={{ backgroundColor: conta ? '#FFFFFF' : '#1E4766', color: conta ? '#1E4766' : '#fff'}} onClick={() => setConta(false)}>SELECIONAR UMA CONTA</button>
          <button className={classes.bntSelect} style={{ backgroundColor: conta ? '#1E4766' : '#FFFFFF', color: conta ? '#fff' : '#1E4766'}} onClick={() => setConta(true)}>ADICIONAR UMA CONTA</button>
          {conta ? (
           <div>
             <CadastrodeConta
              returnState={() => atualizar()}
             />
          </div>
          ) : (         
            <div style={{marginTop: '1.5em'}}>
            
            <Grid sm={12}>
              <PerfectScrollBar>
                <DataTable
                  columns={[
                    { title: "Titular da Conta",field: "titular",  cellStyle: { textAlign: "center" }},
                    { title: "Banco",  cellStyle: { textAlign: "center" }, render:(row) => `${row.codigo_bancario} - ${bancosdb.find(bancos => bancos.id === row.codigo_bancario).name} `},
                    { title: "Agencia",field: "agencia",  cellStyle: { textAlign: "center" }},
                    { title: "Conta",field: "conta",  cellStyle: { textAlign: "center" }},
                    { title: "Digito",field: "digito",  cellStyle: { textAlign: "center" }},                    
                    { title: "Tipo de Conta",  cellStyle: { textAlign: "center" }, render: (row) => row.tipo_conta == 'A' ? 'Alternativa' : 'Principal'},                    
                    { title: "Selecionar Principal",  cellStyle: { textAlign: "center" }, 
                      render: (row) => row.tipo_conta == 'A' ? 
                      <button style={{border: 'none', backgroundColor: 'transparent', cursor: 'pointer'}} onClick={() => update(row.id)}>
                          <Tooltip title="Definir Como Principal">   
                            <InfoRounded style={{color: 'gray', fontSize: '10px'}} />
                          </Tooltip>
                      </button>
                      : 
                      <Tooltip title="Conta Principal">                      
                       <CheckCircle style={{color: 'green', fontSize: '10px'}} />
                      </Tooltip>
                    
                    },                    
                  ]}
                  data={conta_bancaria}
                  options={{
                    headerStyle: {
                      backgroundColor: "#F2F2F3",
                      fontSize: 12,
                      textAlign: "center",
                      borderLeft: "solid 0.5px white",
                    },                     
                  }}
                />
               </PerfectScrollBar>
                <Paginate
                  rowsPerPageOptions={[10, 25, 50]}
                  rowsPerPage={parseInt(per_page)}               
                  count={parseInt(total)}
                  page={parseInt(page) - 1}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Grid>
          </div>
          )}
        </div>
      </Portlet>
    </div>
  );
}

export default DadosBancarios;
