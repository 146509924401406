import http from './index';

const getToken = () => localStorage.getItem('token')

const setErrorHandle = (error, store) => {  
    const errorHandle = {};
    if (error.response === undefined){
      errorHandle.message = error.message;
      errorHandle.modal = false;
      errorHandle.type = "Error";
      errorHandle.trace = error.stack;
      errorHandle.url = error.config.url;
      store.dispatch({ type: 'SET_ERROR', payload: errorHandle });
    } 
    else if (error.response.data !== undefined && error.response.data !== "") {
      errorHandle.message = `${error.request.status} - ${error.request.statusText}`;
      errorHandle.modal = true;
      errorHandle.type = "Error";
      errorHandle.trace = (typeof error.response.data == 'object') ? JSON.stringify(error.response.data) : error.response.data;
      errorHandle.url = error.request.responseURL;
      store.dispatch({ type: 'SET_ERROR', payload: errorHandle });
    }
    else {
      errorHandle.message = `Erro desconhecido`;
      errorHandle.modal = true;
      errorHandle.type = "Error";
      errorHandle.trace = (typeof error.response == 'object') ? JSON.stringify(error.response) : error.response;
      errorHandle.url = error.request.responseURL;
      store.dispatch({ type: 'SET_ERROR', payload: errorHandle });
    }      
}

const responseInterceptor = (store) => {
    http.interceptors.response.use((response) => {
        return response;
    },(error) => {
        setErrorHandle(error, store);
        return Promise.reject(error);
    });
}

const requestInterceptor = (store) => {
    http.interceptors.request.use(config => {
        const token = getToken();
        
        if (token) 
          config.headers.Authorization = `Bearer ${token}`;
      
        if (
            (config.url !== '/api/v1/app/usuarios/autenticar')
            // (config.url !== '/api/v1/usuarios/trocar_empresa') && 
            // (config.url !== '/api/v1/usuarios/simples') && 
            // (config.url !== '/api/v1/usuarios/completo') &&
            // (config.url !== '/api/v1/usuarios/logado_em') && 
            // (config.url !== 'api/v1/menus') &&
            // (config.url !== '/api/v1/usuarios/recuperar_senha')
        ){
        //   http.get('/api/v1/usuarios/logado_em', {headers: { Authorization: `Bearer ${token}`}}).then( response => {
        //     const { usuario, empresas, empresa_atual, configs } = response.data
        //     store.dispatch([
        //       {type: 'LOAD_USUARIO_EMPRESAS', payload: empresas},
        //       {type: 'LOAD_USUARIO_ATUAL', payload: usuario},
        //       {type: 'LOAD_USUARIO_CONFIG', payload: configs},
        //       {type: 'SET_EMPRESA_ATUAL', payload: empresa_atual},
        //     ])
        //   }, error => {
        //     localStorage.removeItem('token');
        //     localStorage.removeItem('isAuthenticated');
        //     history.push('/login');
        //   })
        }
        return config;
    });    
}

export { responseInterceptor, requestInterceptor }