import { combineReducers, createStore, applyMiddleware } from 'redux'
import multi from 'redux-multi'
import thunk from 'redux-thunk'
import promise from 'redux-promise'

import {
    usuarios, 
    sidebar,
    resumo,
    dashboard,
    extrato,
    transacao,
    conta_bancaria
} from './reducers';

const devTools = window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()

const reducers = combineReducers({ 
    usuarios,
    sidebar,
    resumo,
    dashboard,
    extrato,
    transacao,
    conta_bancaria
})

export default applyMiddleware(thunk, multi, promise)(createStore)(reducers, process.env.NODE_ENV === 'development' ? devTools : undefined);