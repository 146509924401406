import React, { useEffect, useState } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import NavBar from './NavBar';
import TopBar from './TopBar';
import { useSelector } from 'react-redux';

const DashboardLayout = () => {
  const navigate = useNavigate()
  useEffect( () => {
    localStorage.getItem('token') == undefined && navigate('/login')
  }, [])

  const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: '#fcfcfc',
      display: 'flex',
      height: '100%',
      overflow: 'hidden',
      width: '100%'
    },
    wrapper: {
      display: 'flex',
      flex: '1 1 auto',
      overflow: 'hidden',
      paddingTop: 35,
      paddingLeft: 48,
      transition: '.3s all'
    },
    contentContainer: {
      display: 'flex',
      flex: '1 1 auto',
      overflow: 'hidden'
    },
    content: {
      flex: '1 1 auto',
      height: '100%',
      overflow: 'auto',
      marginLeft: 200
    }
  }));

  const classes = useStyles();
  
  return (
    <div className={classes.root}>
      <TopBar />
      <NavBar
      />
      <div className={classes.wrapper} >
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;