import React, { } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import _ from 'lodash'

const BarChartPgto = props => {

    const result = props.data
    let search = {}
    let base_data = [
        { qtd_parcelas: 1, parcelas: 0 },
        { qtd_parcelas: 2, parcelas: 0 },
        { qtd_parcelas: 3, parcelas: 0 },
        { qtd_parcelas: 4, parcelas: 0 },
        { qtd_parcelas: 5, parcelas: 0 },
        { qtd_parcelas: 6, parcelas: 0 },
        { qtd_parcelas: 7, parcelas: 0 },
        { qtd_parcelas: 8, parcelas: 0 },
        { qtd_parcelas: 9, parcelas: 0 },
        { qtd_parcelas: 10, parcelas: 0 },
        { qtd_parcelas: 11, parcelas: 0 },
        { qtd_parcelas: 12, parcelas: 0 },
    ]

    let newData = base_data.map( (entry, index) => {
        search = {}
        search = _.find( result , function(elemento) { return elemento.qtd_parcelas === entry.qtd_parcelas })
        if(search !== undefined){return search}else{return entry}
    })

    
    return (
        <div style={{textAlign: 'center', fontSize: '14px'}}>
        <BarChart
            width={500}
            height={190}
            data={newData}
            // margin={{
            //     top: 0,
            //     right: 0,
            //     left: 5,
            //     bottom: 2,
            // }}
            barSize={15}
        >
            <CartesianGrid strokeDasharray="1 1" />
            <XAxis dataKey="qtd_parcelas" style={{ fontSize: 14 }} />
            <YAxis width={70} style={{ fontSize: 12 }} />
            <Tooltip />
            <Legend />
            <Bar dataKey="parcelas" name="Registros" fill="#17C9B2" style={{ fontSize: 10 }} />
        </BarChart>

        </div>
    );

}
export default BarChartPgto