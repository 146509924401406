import { makeStyles } from '@material-ui/styles'
import theme from '../../theme';

const useStyles = makeStyles({
    root: {
        padding: theme.spacing() * 4
      },
      modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
      }
})

export default useStyles;