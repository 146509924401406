import React from 'react'
import { makeStyles } from '@material-ui/styles';
import {Stop as StopIcon} from '@material-ui/icons'

const useStyles = makeStyles({
    square: {
        width: 20,
        height: 20,
        position: 'relative',
        top: '4px'
    },
    text: {
        marginLeft: 0,
        marginRight: 20
    }
})


const InsertLegenda = props => {

    const classes = useStyles()

    const data = props.data

    return(
        <div>
            {data.map( (row, index) => (
                <span > <span> <StopIcon className={classes.square} style={{color: row.color}} /> </span> <span className={classes.text}> {row.name} </span></span>
            ))}
        </div>
    )

}

export default InsertLegenda