import { makeStyles } from '@material-ui/styles';
import theme from '../../theme';
 
const useStyles = makeStyles({
  root: {
    padding: theme.spacing() * 2
  },
  mt5:{
    marginTop: 10
  },
  boldTitle:{
    fontWeight : 'bold'
  },
  blocTipyDesc: {
    borderBottom: '1px black solid'
  },
  totalValues:{
    marginLeft: 10,
  },
  mesas: {
    paddingBottom: '15px'
  },
  mesa: {
    width: '60px',
    height: '60px',
    maxWidth: '60px',
    maxHeight: '60px',
    borderRadius: '25%',
    marginLeft: '25px',
    lineHeight: '60px',
    textAlign: 'center',
    fontSize: 20
  },

  green: {
    backgroundColor: '#2ec940',
    color: '#000',
    '&:hover':{
      backgroundColor: '#7adb86'
    }
  },

  red: {
    backgroundColor: '#e33238',
    color: '#fff',
    '&:hover':{
      backgroundColor: '#dd5a5d'
    }
  },

  gray: {
    backgroundColor: '#b3b3b3',
    color: '#000',
    '&:hover':{
      backgroundColor: '#d1d1d1'
    }
  },

  yellow: {
    backgroundColor: '#e7ea2c',
    color: '#000',
    '&:hover':{
      backgroundColor: '#edf051',
    }
  },

  badge: {
    width: 60,
    height: 60,

  },

  titleMT: {
    marginTop: 20,
    marginBottom:20
  }

  
});

export default useStyles;