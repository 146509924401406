import * as types from './../types/conta_bancaria';

const INITIAL_STATE = { 

  loading: false,
  paginate: false,
  conta_bancaria: [],
  page: 1,
  per_page: 10,
  total: 0,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.LOADING_CONTA_BANCARIA:
      return { ...state, loading: action.payload };
    case types.LOAD_CONTA_BANCARIA:
      return { ...state, conta_bancaria: action.payload };
    case types.LOAD_CONTA_BANCARIA_PAGE:
      return { ...state, page: action.payload };
    case types.LOAD_CONTA_BANCARIA_PERPAGE:
      return { ...state, per_page: action.payload };
    case types.LOAD_CONTA_BANCARIA_TOTAL:
      return { ...state, total: action.payload };
    default:
      return state;
  }
};