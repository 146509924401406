import React, {useState} from 'react'
import { makeStyles } from '@material-ui/styles';
import {
    Accordion, AccordionSummary, Typography, Divider, 
    AccordionDetails, AccordionActions, Button
} from '@material-ui/core';

import { 
    ExpandMore as ExpandMoreIcon, FilterList as FilterListIcon,
    Clear as ClearIcon
} from '@material-ui/icons'

const useStyles = makeStyles({
    filterPanel: {
        marginTop: 0
    }, 
    divHeader: {
        marginTop: '5px'
    },
    divcontaineir: {
        marginBottom: 10,
        marginLeft: 10,
        marginRight: 10,
        width: '99%'
    }
})

function FilterBar({ title, children, searchFilter, clearFilter}) {
    const classes = useStyles();
    const [expanded, setExpanded] = useState(false)

    return (
        <div className={classes.divcontaineir} style={{display: 'grid', gridTemplateColumns : `auto 100`,}}>
            <Accordion className={classes.filterPanel} expanded={expanded} onChange={(e,expanded)=> setExpanded(expanded)} >
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1c-content" id="panel1c-header">
                    <Typography variant="caption" component="p">
                        { title }
                    </Typography>
                </AccordionSummary>
                <Divider />
                <AccordionDetails>
                    { children }
                </AccordionDetails>
                <Divider />
                <AccordionActions>
                    <Button size="small" onClick={ () => {setExpanded(!expanded); searchFilter()}}>
                        <FilterListIcon /> Filtrar
                    </Button>
                    <Button size="small" color="primary" onClick={ () => {setExpanded(!expanded); clearFilter()}}>
                        <ClearIcon /> Limpar
                    </Button>
                </AccordionActions>
            </Accordion>
        </div>
    )
}

export default FilterBar