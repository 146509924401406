import React, { useState } from "react";
import { Divider, Grid, InputAdornment,Button, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Portlet, InputMask } from "../../../components";
import { Info as InfoIcon, Check, Receipt, Visibility, VisibilityOff } from "@material-ui/icons";
import { useSelector, useDispatch } from "react-redux";
import RSTextField from "./../../../components/RSTextField/index";
import { Link, useNavigate } from "react-router-dom";
import { ArrowLeftCircle, ArrowRightCircle, CheckCircle } from "react-feather";
import { validarSenha } from "../../../store/actions/saque";
import Swal from "sweetalert2";
import DadosBancarios from "./dados_bancarios";

const useStyles = makeStyles({
  dados: {
    textAlign: "center",
    marginTop: "2em",
    padding: "2em",
    fontSize: "14px",
    width: "90%",
    margin: "auto",
  },
  confirm: {
    color: "#0d85bc",
    border: "1px solid #d3d3d3",
    height: '25px',
    borderRadius: "4px 0px 0px 4px",
  },
  noconfirm: {
    color: "#4c4c4c",
    border: "1px solid #d3d3d3",
    height: '25px',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    display: "flex",
  },
  information: {
    width: "98%",
    margin: "auto",
    marginTop: "1em",
    marginBottom: "1em",
    padding: "5px",
    backgroundColor: "#ecffce",
    color: "#4F7F13",
    fontSize: "14px",
    borderRadius: "4px",
    display: "flex",
    flexDirection: "row",
    // justifyContent: 'center',
    alignItems: "center",
  },
  contasbancarias: {
    width: "96%",
    padding: "5px",
    margin: "auto",
    marginTop: "2em",
    color: "#454550",
    lineHeight: "1.8em",
    textAlign: "center",
    "& h4": {
      fontWeight: "normal",
      color: "gray",
    },
  },
  cardSaque:{
    boxShadow: '0px 2px 10px 0px #cecece',  
    borderRadius: '5px',
    height: "150px",
    paddingTop: '2em',
    paddingBottom: '0.5em',
  
  },
  NextButton:{
    width: '90%',
    padding: '2em',
    textAlign: 'right',
   '& button':{
     backgroundColor: '#1E4766',
     color: '#fff',
     padding: '12px 15px',
     marginLeft: '2em',
     '&:hover':{
       backgroundColor: '#1b669b'
     },
     '&:disabled':{
       backgroundColor: '#d3d3d3',
       color: '#fff'
     }
   }
  }
});

const toCurrency = (value) => {
  let reais = new Intl.NumberFormat("pt-BR", {
    currency: "BRL",
    style: "currency",
  }).format(value);
  return reais;
};

function Conclusao({ next, prev, valor, date }) {
  const navigate = useNavigate();
  const classes = useStyles();
  const { saldo } = useSelector((state) => state.extrato);
  const { usuario } = useSelector((state) => state.sidebar)


  const [showPass, setShowPass] = useState(false);
  const [data, setData] = useState({ email: "", password: "" });

  function ShowPassword() {
    setShowPass(showPass ? false : true);
  }

  const valorfinal = toCurrency(valor.saque - valor.taxa);

  



  return (
    <>
      <div style={{display: 'flex', flexDirection: 'row', padding: '1.2em'}}><Receipt />&nbsp;Saque</div>
      <Divider />
      
      <div className={classes.dados}>
        <Grid container spacing={1} alignItems="center">
          <Grid item sm={4} xs={4} className={classes.noconfirm}>
            <CheckCircle style={{color: '#49BED7', width: '14px'}}/>. DADOS
          </Grid>
          <Grid item sm={4} xs={4} className={classes.noconfirm}>
          <CheckCircle style={{color: '#49BED7', width: '14px'}}/>. CONFIRMAÇÃO
          </Grid>
          <Grid item sm={4} xs={4} className={classes.noconfirm} style={{color: '#49BED7',}}>
          <CheckCircle style={{ width: '14px'}}/>.  CONCLUSÃO
          </Grid>
        </Grid>
      </div>
      <Portlet>   
      <div className={classes.information}>
          <CheckCircle style={{ width: "14px" }} /> &nbsp;
          <b>Seu saque será encaminhado ao banco até o final do dia caso tenha sido feito até às 15h, 
            no horário de Brasília. Após isso, ele será encaminhado somente no próximo dia útil.</b>
        </div>
        <DadosBancarios />
        <Grid
          container
          spacing={1}
          alignItems="center"
          className={classes.contasbancarias}
        >     
          <Grid item sm={3} xs={3} >
            <div className={classes.cardSaque}>
            <h4>Data de saída</h4>
            <h3> {date}</h3>
            </div>
          </Grid>
          <Grid item sm={3} xs={3} >
          <div className={classes.cardSaque}>
            <h4> Valor Solicitado</h4>
            <h3> {toCurrency(valor.saque)}</h3>
            </div>
          </Grid>
          <Grid item sm={3} xs={3}>
          <div className={classes.cardSaque}>
            <h4> Taxa de transferência</h4>
            <h3> {toCurrency(valor.taxa)}</h3>
            </div>
          </Grid>
          <Grid item sm={3} xs={3}>
          <div className={classes.cardSaque}>
            <h4>Valor a ser transferido</h4>
            <h3 >{valor.saque > saldo  ?  <b style={{color: '#dd3737'}}>-</b> : <b style={{color: 'green'}}>+</b> } {valorfinal < "R$ 0,00" ? 'R$ 0,00' : valorfinal}</h3>
            <h6>Saldo disponível após a transção {toCurrency(saldo - valor.saque)}</h6>
            </div>
          </Grid>
          Em caso de dúvidas, consulte as perguntas frequentes na FAQ. Você também pode entrar em contato conosco através do 
          email suporte@conexopay.com.br, ou ligando para (16)3965-9020 (capitais e regioes metropolitanas) .
        </Grid>
      </Portlet>
    </>
  );
}

export default Conclusao;