import palette from '../palette';

export default {
  root: {
    '&:hover:not($disabled)': {
      backgroundColor: palette.background.default
    }
  },
  input: {
    padding: "15.5px 14px",
  }
};
