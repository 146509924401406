import React, { useState, useEffect  } from 'react';
import { useDispatch, useSelector } from 'react-redux'

import useStyles from './styles';

import {
  Grid, Button, CircularProgress, TextField, Typography,
  Box,
  Link
} from '@material-ui/core';

import validate from 'validate.js';
import * as types from '../../store/types/usuarios'
import { loginApp } from '../../store/actions/usuarios'

import logo from '../../assets/images/conexopay-branco.png';
import { useNavigate } from 'react-router';

function Login(props) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { credenciados, modal_credenciado, loading} = useSelector( state => state.usuarios)
  const classes = useStyles();

  const [values, setValues] = useState({usuario: '', senha: '', credenciado:0})
  const [touched, setTouched] = useState({usuario: false, senha: false})
  const [errors, setErrors] = useState({usuario: null, senha: null})
  const [isValid, setIsValid] = useState(false)
  const [submitError, setSubmitError] = useState(null)

  const showEmailError = touched.usuario && errors.usuario;
  const showPasswordError = touched.senha && errors.senha;

  useEffect( () => {
    if(modal_credenciado === true)
      setValues({...values, credenciado: credenciados[0].id })
  }, [modal_credenciado]);

  useEffect(() => {
    let contain_errors = validate(values, {
      usuario: {
        presence: { allowEmpty: false, message: 'é obrigatório' },
      },
      senha: {
        presence: { allowEmpty: false, message: 'é obrigatória' },
        length: {
          maximum: 128
        }
      }
    });
    setErrors(contain_errors || {})
    setIsValid(contain_errors ? false : true)
  }, [values])

  function handleFieldChange(field, value){
    const newValues = { ...values }
    const newTouched = { ...touched }

    setSubmitError(null);
    newTouched[field] = true;
    newValues[field] = value;

    setValues({...newValues})
    setTouched({...newTouched})
  }
    
  function handleSignIn(){
    let credenciado = values.credenciado
    setValues({...values, credenciado: 0})
    dispatch([
      {type: types.SET_USUARIO_LOADING, payload: true},
      loginApp(values.usuario, values.senha, credenciado, navigate)
    ])
  }

  function onClickHide(){ 
    setValues({...values, credenciado: 0 })
    dispatch({type: types.SET_MODAL_USUARIO_CREDENCIADOS, payload: false}) 
  }

  return (
      <div className={classes.root}>
        <Grid className={classes.grid} container>
          <Grid className={classes.quoteWrapper} item lg={5}>
            <div className={classes.quote}>
              <div className={classes.quoteInner}>
                <img src={logo} alt="Logomarca" style={{width: '30%', height: 'auto'}} />
                <div>
                  <Typography variant="body1" style={{marginTop: 30, color: '#ffffff'}}>
                    SOLUÇÃO IDEAL PARA VOCÊ RECEBER PAGAMENTOS
                  </Typography>
                  <Typography variant="body2"  style={{color: '#ffffff'}}>
                    sac@conexopay.com.br
                  </Typography>
                </div>
              </div>
            </div>
          </Grid>
          <Grid className={classes.content} item lg={7} xs={12}>
            <div className={classes.content}>
              <div className={classes.contentHeader}>
            </div>
            <div className={classes.contentBody}>
              <form className={classes.form}>
                <Typography className={classes.title} variant="h2">
                  Entrar
                </Typography>
                <div className={classes.fields}>
                    <TextField
                        className={classes.textField}
                        label="Usuário"
                        name="Usuário"
                        InputLabelProps={{ shrink: true }}
                        onChange={event => {handleFieldChange('usuario', event.target.value)}}
                        type="text"
                        value={values.usuario}
                        variant="outlined"
                    />
                    <TextField
                        className={classes.textField}
                        label="Senha"
                        name="senha"
                        InputLabelProps={{ shrink: true }}
                        onChange={event =>
                        handleFieldChange('senha', event.target.value)
                        }
                        onKeyPress={(e) => e.key === 'Enter' ? handleSignIn() : null}
                        type="password"
                        value={values.senha}
                        variant="outlined"
                    />
 
                </div>
                <Box my={2}>
                <Button
                    className={classes.signInButton}
                    color="primary"
                    disabled={!isValid}
                    onClick={handleSignIn}
                    size="large"
                    variant="contained"
                    style={{
                      marginTop: 25,
                      width: '100%',
                    }}
                    >
                    Entrar
                  </Button>
                </Box>
                <div style={{display: 'flex', flexDirection: 'row', alignContent: 'space-evenly'}}>
                    <Typography className={classes.signUp} variant="body1">
                        <Link href="/recuperar-senha" className={classes.recoverPass}>
                            Esqueci minha senha?
                        </Link>
                    </Typography>  
                    <Typography className={classes.signUp} variant="body1">
                        <Link href="/recuperar-senha" className={classes.recoverPass}>
                            Quero me Cadastrar!
                        </Link>
                    </Typography>               
                </div>
              </form>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default Login;