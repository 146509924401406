import React, {} from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';


const BudgetChart = props => {

  const data = props.data

  function onlyMoreThanZero(obj) {
    if(obj.value > 0){
      return obj
    }
  }

 let newData = data.filter(onlyMoreThanZero)

  //  P - pendente, N - negado, A - aprovado, F - Finalizado, E - Expirado, C - Cancelado

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  return (
    <div style={{height: 250}}>
      <ResponsiveContainer className="container" width="99%" height="99%" >
        <PieChart>
          <Pie
            data={newData}
            cy={100}
            labelLine={false}
            label={renderCustomizedLabel}
            outerRadius={100}
            fill="#8884d8"
            dataKey="value"
          >
            {newData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </div>
  );

}
export default BudgetChart