import axios from 'axios';

const baseUrl = () => {
  if (process.env.NODE_ENV !== 'development') {    
    return 'https://api.conexopay.com.br'
  } else {
    return 'http://localhost:3002'
  }
}

const http = axios.create({ 
  baseURL: baseUrl(), 
  timeout: 20000,
  withCredentials: false,
  headers: {
    'Access-Control-Allow-Origin' : '*',
    'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    }
});

export default http;