import { makeStyles } from '@material-ui/styles';
import theme from '../../../theme';

const useStyles = makeStyles({
    root: {
      minWidth: 275,
      minHeight: 180,
    },
    bullet: {
      display: "inline-block",
      margin: "0 2px",
      transform: "scale(0.8)",
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    centralizar: {
      textAlign: "center",
    },
    mtop: {
      marginTop: "10%",
    },
    btnPad: {
        fontSize: 10, 
        margin: '15px 50px',
    },
    statusContainer:{
      fontSize: '12px',
      display: 'flex',
      alignContent: 'center',
      justifyContent: 'center',
      flexDirection: 'row',
      marginLeft: '-4em'
    },

    analise:            {backgroundColor: '#235894', color: 'white', fontSize: 11},
    recusada:           {backgroundColor: '#DF285F', color: 'white', fontSize: 11},
    paga:               {backgroundColor: '#23d143', color: 'white', fontSize: 11},
    suspensa:           {backgroundColor: '#C25409', color: 'white', fontSize: 11},
    autorizada:         {backgroundColor: '#edc91a', color: 'black', fontSize: 11},
    cancelada:          {backgroundColor: '#660909', color: 'black', fontSize: 11},
    desconhecido:       {backgroundColor: '#FFFFFF', color: 'black', fontSize: 11},
    
    estorno_pendente:   {backgroundColor: '#3F2787', color: 'white', fontSize: 11},
    indisponivel:       {backgroundColor: '#72737A', color: 'white', fontSize: 11},
    revisao_pendente:   {backgroundColor: '#EC6E15', color: 'white', fontSize: 11},
    aguardando_pgto:    {backgroundColor: '#F3B700', color: 'white', fontSize: 11},
    chargeback:         {backgroundColor: '#070817', color: 'white', fontSize: 11},
    processando:        {backgroundColor: '#5691D6', color: 'white', fontSize: 11},
    cbk_reapresentado:  {backgroundColor: '#A71A44', color: 'white', fontSize: 11},
    estornada:          {backgroundColor: '#6045AF', color: 'white', fontSize: 11},
    fraude_coberta:     {backgroundColor: '#E2EFFF', color: 'black', fontSize: 11},
    
  });



  export default useStyles