import * as types from '../types/resumo'

const INITIAL_STATE = {
    loading: false,
    resumo_estatisticas: {
        numero_transacoes: 0,
        ticket_medio: 0,
        volume_transacionado: 0,
        transacoes_por_status: [],
        formas_de_pagamento: [],
        bandeiras_utilizadas: [],
        transacoes_recusadas: 0,
        quantidade_parcelas_cc: 0,
        indices_conversao: {
          indice_real: {titulo: '', percent: 0, dados: []},
          indice_sem_retentativas: {titulo: '', percent: 0, dados: []},
          boleto: {titulo: '', percent: 0, dados: []},
        },
        volume_dia_semana: []
    }
}

export default (state = INITIAL_STATE, action) => {
    switch(action.type){
      case types.SET_RESUMO_ESTATISTICAS:
        return {...state, resumo_estatisticas: action.payload}
      case types.LOADING_RESUMO:
        return {...state, loading: action.payload}
      default:
        return state
    }
  }