import { makeStyles } from "@material-ui/styles";
import theme from "../../theme";

const useStyles = makeStyles({
  root: {
    padding: theme.spacing() * 2,
  },
  mt5: {
    marginTop: 10,
  },
  titleMT: {
    marginTop: 20,
    marginBottom: 20,
  },
});

export default useStyles;
