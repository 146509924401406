import React from 'react';
import { Navigate } from 'react-router-dom';

import MainLayout from './layouts/MainLayout';
import DashBoardLayout from './layouts/DashBoardLayout'

import Login from './views/Login';
import Resumo from './views/Resumo';
import Extrato from './views/Extrato';
import Transacoes from './views/Transacoes';
import VisualizarTransacoes from './views/Transacoes/visualizar'
import Saque from './views/Saque/index';
import Cadastro from './views/Cadastro/index';
import DadosBancarios from './views/DadosBancarios/index';
import AlterarSenha from './views/AlterarSenha/index';

const routes = [
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'login', element: <Login /> },
    //   { path: '404', element: <NotFoundView /> },
      { path: '/', element: <Navigate to="/login" /> },
    //   { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: 'dashboard',
    element: <DashBoardLayout />,
    children: [
      { path: 'resumo', element: <Resumo /> },
      { path: 'extrato', element: <Extrato /> },
      { path: 'transacoes', element: <Transacoes /> },
      { path: 'saque', element: <Saque /> },
      { path: 'transacoes/:id', element: <VisualizarTransacoes /> },
      { path: 'cadastro', element: <Cadastro /> },
      { path: 'contas_bancarias', element: <DadosBancarios /> },
      { path: 'senha', element: <AlterarSenha /> }
    ]
  }
];

export default routes;