import {
    Button,
    Grid,
    Paper,
    Card,
    CardContent,
    Typography,
    Chip
  } from "@material-ui/core";
  import {useNavigate} from 'react-router-dom'
  import { 
    ViewModule, 
    FormatListBulleted as FormatListBulletedIcon,
  } from "@material-ui/icons";
  import React, { useSate } from "react";
  import { PortletHeader, RSPaginate } from "../../../components";
  import CXDataGrid from './CXDataGrid'
  import PerfectScrollBar from "react-perfect-scrollbar";
  import useStyles from './stylesTableList'
  import dayjs from "dayjs";
  import customParseFormat from "dayjs/plugin/customParseFormat";
  import { useSelector, useDispatch } from "react-redux";
  dayjs.extend(customParseFormat)

  
  const TableListExt = (props) => {
    const { 
            lista_transacoes: extrato, 
            transaction_perpage: ext_perpage, 
            transaction_page: ext_page, 
            transaction_total: ext_total 
          } = props.dados;

    const {handleChangeTransactionPage, handleChangeTransactionRowsPerPage} = props.changes

    const daysResumo = useSelector((state) => state.dashboard);

   let newExtrato = []
   let txt_status

    const navigate = useNavigate()
    const classes = useStyles()

    extrato.forEach( row => {
      if(row.status == 'P'){
        txt_status = 'pendente'
      }else if(row.status == 'N'){
        txt_status = 'negado'
      }else if(row.status == 'A'){
        txt_status = 'aprovado'
      }else if(row.status == 'F'){
        txt_status = 'finalizado'
      }else if(row.status == 'E'){
        txt_status = 'expirado'
      }else if(row.status == 'C'){
        txt_status = 'cancelado'
      }

      newExtrato.push({...row, txt_status})

    })

    const toCurrency = (value) => {
      let reais = new Intl.NumberFormat('pt-BR', {
        currency: 'BRL',
        style: 'currency',
      }).format(value);
      return reais;
    }
  
    return (
      <Grid
        container
        sm={12}
        direction="row"
        justify="center"
        alignContent="center"
        className={classes.root}
      >  
        <Grid sm={12} container style={{ marginTop: 10 }}>      
  
          <Grid sm={12}>
            <PerfectScrollBar>
              <CXDataGrid
                columns={[
                  {
                    title: 'STATUS',
                    field: 'status',                   
                    render: rowData => 
                    <div className={classes.statusContainer}>
                      <div style={{padding: '0.5em', width: '5%', borderRadius: "50%"}} className={
                      rowData.txt_status == 'finalizado' ?classes.paga
                      : rowData.txt_status == 'pendente'?classes.analise
                      : rowData.txt_status == 'negado'?classes.recusada
                      : rowData.txt_status == 'aprovado'?classes.autorizada
                      : rowData.txt_status == 'expirado'?classes.suspensa
                      : rowData.txt_status == 'cancelado'?classes.cancelada

                      : classes.desconhecido
                    }
                    >
                     </div> &nbsp;
                     <div style={{
                       width: '10px',
                       marginLeft: '2em',
                       color: '#3f3f3f',
                     }} >
                      {rowData.txt_status.toUpperCase()}
                      </div>
                    </div>
                  },
                  { title: "DATA DA TRANSAÇÃO", field: "data_transacao", cellStyle:{ textAlign: 'center'}, 
                  render: rowData => <span> {
                  dayjs(rowData.data_transacao, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY')
                  } {rowData.hora_transacao}
                  </span>    },
                  { title: "ID DA TRANSAÇÃO",  field: "id_transacao", cellStyle:{ textAlign: 'center'} },
                  { title: "NOME", render : row => <div  style={{maxWidth: '25ch', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: "nowrap"}}>{row.nome}</div> },
                  { title: "FORMA DE PAGAMENTO", field: "forma_pagamento", cellStyle:{ textAlign: 'center'}},
                  { title: "VALOR DA TRANSAÇÃO", cellStyle:{ textAlign: 'center'}, render: row => <>{toCurrency(row.valor_transacao)}</>  },
                ]}
                data={newExtrato}
                options={{
                  headerStyle: {
                    backgroundColor: "#F2F2F3",
                    fontSize: 12,
                    textAlign: "center",
                    borderLeft: "solid 0.5px white",
                  },
                  rowStyle: (rowData) => ({
                    fontSize: 14,
                    textAlign: "center",
                  }),
                }}
                onRowClick={(event, rowData)=>navigate(`${rowData.id_transacao}`)}
              />
              <RSPaginate
                rowsPerPageOptions={[10, 30, 50, 100]}
                rowsPerPage={ext_perpage}
                count={ext_total}
                page={ext_page - 1}
                onChangePage={(event, newPage) => handleChangeTransactionPage(newPage)}
                onChangeRowsPerPage={(e) => handleChangeTransactionRowsPerPage(e)}
              />
            </PerfectScrollBar>
          </Grid>
        </Grid>
      </Grid>
    );
  };
  
  export default TableListExt;
  