import React, { useEffect } from 'react'
import { Grid } from '@material-ui/core'
import { useSelector, useDispatch } from "react-redux";
import { loadContasBancarias } from './../../../store/actions/contas_bancarias';
import bancosdb from './../../DadosBancarios/bancos';
import { Info as InfoIcon, MonetizationOn, Receipt } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  
    information: {
      width: "98%",
      margin: "auto",
      marginTop: "1em",
      marginBottom: "1em",
      padding: "5px",
      backgroundColor: "#E2EFFF",
      color: "#235894",
      fontSize: "14px",
      borderRadius: "4px",
      display: "flex",
      flexDirection: "row",
      // justifyContent: 'center',
      alignItems: "center",
    },
    contasbancarias: {
      width: "96%",
      padding: "5px",
      margin: "auto",
      marginTop: "2em",
      color: "#454550",
      lineHeight: "1.8em",
      textAlign: "center",
      "& h4": {
        fontWeight: "normal",
        color: "gray",
      },
    },
   
  });

function DadosBancarios(){

    const classes = useStyles()
    const dispatch = useDispatch();
    const { conta_bancaria } = useSelector((state) => state.conta_bancaria );
    const data = conta_bancaria.find( item => item.tipo_conta === 'P')

    const banco_nome = bancosdb.find( item => item.id === data?.codigo_bancario)

useEffect(() => {
    dispatch(loadContasBancarias());
  }, []);



    return(
        <>
        <div className={classes.information}>
          <InfoIcon style={{ width: "14px" }} />
          &nbsp; Para receber os seus pagamentos corretamente a&nbsp;
          <b>conta bancária deve estar vinculada ao documento de cadastro</b>
        </div>
        <Grid
          container
          spacing={1}
          alignItems="center"
          className={classes.contasbancarias}
        >
          <Grid item sm={3} xs={3}>
            <h4> Nome/Razão social do favorecido</h4>
            <b>{data?.titular}</b>
          </Grid>
          <Grid item sm={4} xs={1}>
            <h4>Banco</h4>
            <b>{data?.codigo_bancario} - {banco_nome?.name}</b>
          </Grid>
          <Grid item sm={1} xs={1}>
            <h4>Agência</h4>
            <b>{data?.agencia}</b>          
          </Grid>
          <Grid item sm={1} xs={1}>
            <h4>Conta</h4>
            <b>{data?.conta}</b>
          </Grid>       
        </Grid>
        </>
    )
}

export default DadosBancarios