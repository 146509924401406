import { makeStyles } from "@material-ui/styles";
import theme from "../../theme";

const useStyles = makeStyles({
  root: {
    padding: theme.spacing() * 2,
  },
  mt5: {
    marginTop: 10,
  },
  titleMT: {
    marginTop: 20,
    marginBottom: 20,
  },
  bntSelect:{
    padding: '12px',
    border: 'none',
    borderRadius: '4px',
    marginLeft: '1em',
    marginTop: '1em',
    cursor: 'pointer',   
    fontSize: '11px',
    boxShadow: '0px 0px 11px 2px #e0e0e0'
  }
});

export default useStyles;
