import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
} from "@material-ui/core";

import dayjs from 'dayjs'

import {makeStyles} from '@material-ui/styles'

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },

  heading: {
      //width: 250,
        lineHeight: 3,
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
  },

  bola: {
    borderRadius: '50%',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    height: 45,
    width: 45,
    border: '1px solid white',
    backgroundColor: 'white',
    position: 'relative',
  },
  analise:            {backgroundColor: '#235894', color: 'white', fontSize: 11},
  recusada:           {backgroundColor: '#DF285F', color: 'white', fontSize: 11},
  paga:               {backgroundColor: '#65A300', color: 'white', fontSize: 11},
  suspensa:           {backgroundColor: '#C25409', color: 'white', fontSize: 11},
  autorizada:         {backgroundColor: '#F8CC43', color: 'black', fontSize: 11},
  cancelada:          {backgroundColor: '#FFFFFF', color: 'black', fontSize: 11},
  desconhecido:       {backgroundColor: '#FFFFFF', color: 'black', fontSize: 11},

}));

const AccordionComponent = (props) => {
  const classes = useStyles();
  const historico = props.dados
  
  const toCurrency = (value) => {
    let reais = new Intl.NumberFormat("pt-BR", {
      currency: "BRL",
      style: "currency",
    }).format(value);
    return reais;
  };

  
  return (
    <div>

      {historico.map( (row, index) => (
        
        <Accordion className={
          row.status == 'P'? classes.analise
          :row.status == 'N'? classes.recusada
          :row.status == 'A'? classes.autorizada
          :row.status == 'F'? classes.paga
          :row.status == 'E'? classes.suspensa
          :row.status == 'C'? classes.cancelada
          :classes.desconhecido} style={{margin: 0}} key={index}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{borderRadius: '50%',backgroundColor: 'white', width: 30, height: 30, position: 'relative'}}/>}
          container
          aria-controls="panel2a-content"
          id="panel2a-header"
          style={{display: 'flex', flexDirection: 'column', position: 'relative'}}
          >
            <Grid 
                container 
                style={{ width: 400, maxWidth: 400}} 
                className={classes.lineWhite}
                direction="row"
                justify="space-between"
                alignItems="center"
                >
              <Grid item sm={1} style={{position: 'absolute', width: 1, height: 200, backgroundColor: 'white', left: 50, top: 50}}></Grid>
                <Grid item sm={2} >
                    <Typography ><span className={classes.bola} style={{fontSize: 30, color: 'black'}}>{(row.arralen-index)}</span></Typography>
                </Grid>

                <Grid item sm={7}>
                    <Typography className={classes.heading}> {row.status == 'P'? 'PENDENTE'
                                                :row.status == 'N'? 'NEGADO'
                                                :row.status == 'A'? 'APROVADO'
                                                :row.status == 'F'? 'FINALIZADO'
                                                :row.status == 'E'? 'EXPIRADO'
                                                :row.status == 'C'? 'CANCELADO'
                                                :'DESCONHECIDO'} </Typography>
                </Grid>

            </Grid>


            

        </AccordionSummary>
        <AccordionDetails container style={{display: 'flex'}}>
        <Grid 
                container 
                style={{ width: 400, marginLeft:10}} 
                direction="row"
                justify="space-between"
                alignItems="center"
                >
                <Grid item sm={2}>
                </Grid>

                <Grid item sm={7}>
                    <Typography className={classes.heading}>{dayjs(row.created_at).format('HH:mm:ss - DD/MM/YYYY')}</Typography>
                    <Typography className={classes.heading}>Valor: <strong>{ toCurrency(row.valor_bruto)}</strong></Typography>
                    {row.status == 'N' ? <>Observação: {row.observacao}</> : ''}
                </Grid>

            </Grid>
        </AccordionDetails>
      </Accordion>
      )
      )}

    </div>
  );
};

export default AccordionComponent;
