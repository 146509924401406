import React from "react";
import { Grid, Card, CardContent, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

import * as FeatherIcon from "react-feather";

const useStyles = makeStyles({
  container: {
    boxShadow: "0px 0px 9px 2px #e0e0e0",
    borderRadius: "7px",
    height: "100%",
    '&:hover':{
        backgroundColor: '#f4fcf6',
        transition: '0.3s ease-in-out',
        cursor: 'pointer'
    },
    "& h5": {
      textAlign: "center",
      fontWeight: "normal",
      color: "grey",
      paddingTop: "2em",
      marginBottom: "-2.5em",
    },
  },
  centralizar: {
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    display: "flex",
    width: "100%",
    height: "100%",
    padding: "2em",
  },
});

const CardComponent = (props) => {
  const classes = useStyles();
  return (

    <Grid item sm={props.grid || 3}>
      <div className={classes.container}>
        <h5>{props.title}</h5>
        <Grid container sm={12} spacing={1} className={classes.centralizar}>
          <Grid
            sm={12}
            style={{ fontSize: "24px", fontWeight: "bold", color: "#494949", textAlign: 'left' }}
          >
            {props.first}
            <div style={{float: 'right'}}>{props.icon}</div>
          </Grid>
          {/* <Grid  style={{fontWeight: 'bold', color: 'gray', textAlign :'right' }}>{props.second} </Grid> */}
        </Grid>
        
      </div>
    </Grid>
  );
};

export default CardComponent;
