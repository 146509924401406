import { makeStyles } from "@material-ui/styles";
import theme from "../../theme";

const useStyles = makeStyles({
  root: {
    padding: theme.spacing() * 2,
  },
  rootTab: {
    flexGrow: 1,
  },
  mt5: {
    marginTop: 10,
  },
  boldTitle: {
    fontWeight: "bold",
    color: "#595959",
  },
  blocTipyDesc: {
    borderBottom: "1px black solid",
  },
  totalValues: {
    marginLeft: 10,
  },
  mesas: {
    paddingBottom: "15px",
  },
  mesa: {
    width: "60px",
    height: "60px",
    maxWidth: "60px",
    maxHeight: "60px",
    borderRadius: "25%",
    marginLeft: "25px",
    lineHeight: "60px",
    textAlign: "center",
    fontSize: 20,
  },

  green: {
    backgroundColor: "#2ec940",
    color: "#000",
    "&:hover": {
      backgroundColor: "#7adb86",
    },
  },

  red: {
    backgroundColor: "#e33238",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#dd5a5d",
    },
  },

  gray: {
    backgroundColor: "#b3b3b3",
    color: "#000",
    "&:hover": {
      backgroundColor: "#d1d1d1",
    },
  },

  yellow: {
    backgroundColor: "#e7ea2c",
    color: "#000",
    "&:hover": {
      backgroundColor: "#edf051",
    },
  },

  badge: {
    width: 60,
    height: 60,
  },

  titleMT: {
    marginTop: 20,
    marginBottom: 20,
  },

  descriptCliente: {
    marginTop: 5,
    fontSize: 14,
    fontWeight: "normal",
  },

  descriptAccount: {
    fontSize: 16,
    color: '#1d3b51'
  },

  marTop: {
    color: "purple",
  },

  btnExt: {
    maxWidth: 200,
    backgroundColor: "#65A300",
    color: "white",
    marginTop: 25,
    marginLeft: 50 + "%",
    transform: "translateX(-50%)",
    border: "none",
    fontSize: 14,
    fontWeight: "bold",

    "&:hover": {
      backgroundColor: "#7FCC01",
      border: "none",
    },
  },
  circle: {
    borderRadius: "50%",
    width: '50%',
    padding: '0.7em',
    backgroundColor: "#565656",
    color: "#fff",
    display: 'block'
  },
  cicleContainer: {
    width: '99%',
    textAlign: 'center'
  },
});

export default useStyles;
