import { makeStyles } from "@material-ui/styles";
import theme from "../../theme";

const useStyles = makeStyles({
  root: {
    padding: theme.spacing() * 2,
  },
  margin: {
    margin: theme.spacing(1),
  },
  rootTab: {
    flexGrow: 1,
  },
  mt5: {
    marginTop: 10,
  },
  boldTitle: {
    fontWeight: "bold",
  },
  blocTipyDesc: {
    borderBottom: "1px black solid",
  },
  totalValues: {
    marginLeft: 10,
  },
  mesas: {
    paddingBottom: "15px",
  },
  mesa: {
    width: "60px",
    height: "60px",
    maxWidth: "60px",
    maxHeight: "60px",
    borderRadius: "25%",
    marginLeft: "25px",
    lineHeight: "60px",
    textAlign: "center",
    fontSize: 20,
  },

  green: {
    backgroundColor: "#2ec940",
    color: "#000",
    "&:hover": {
      backgroundColor: "#7adb86",
    },
  },

  red: {
    backgroundColor: "#e33238",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#dd5a5d",
    },
  },

  gray: {
    backgroundColor: "#b3b3b3",
    color: "#000",
    "&:hover": {
      backgroundColor: "#d1d1d1",
    },
  },

  yellow: {
    backgroundColor: "#e7ea2c",
    color: "#000",
    "&:hover": {
      backgroundColor: "#edf051",
    },
  },

  badge: {
    width: 60,
    height: 60,
  },

  titleMT: {
    marginTop: 20,
    marginBottom: 20,
  },

  descriptCliente: {
    marginTop: 5,
    fontSize: 14,
    fontWeight: "normal",
  },

  descriptAccount: {
    fontSize: 18,
  },

  marTop: {
    color: "purple",
  },

  btnExt: {
    maxWidth: 200,
    backgroundColor: "#65A300",
    color: "white",
    marginTop: 25,
    marginLeft: 50 + "%",
    transform: "translateX(-50%)",
    border: "none",
    fontSize: 14,
    fontWeight: "bold",

    "&:hover": {
      backgroundColor: "#7FCC01",
    },
  },
  mt10: {
    marginTop: 30,
  },
  blockContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  filterPanel: {
    marginBottom: 10,
    marginTop: 5,
  },
  btnGroup: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  btnTabNoSelected: {
    color: "#686868",
    backgroundColor: 'white',
    width: '70px',
    height: '30px',
    border:'none',
    border:'1px solid #f4f4f4',
    cursor: 'pointer',
    
  },
  btnTabSelected: {
    color: "#258794",
    backgroundColor: '#f4eded',
    width: '70px',
    height: '30px',
    border: 'none',
    cursor: 'pointer',
  },
  buttonSearch:{
    border: 'none',
    padding: '15px 15px',
    borderRadius: '5px',
    backgroundColor: '#29506E',
    color: '#fff',
    marginTop: '1em',
    cursor: 'pointer',
    '&:hover':{
      transition: '0.3s ease-in-out',
      backgroundColor: '#0d3859'
    }

  },

});

export default useStyles;
