import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux'
import { Dialog, DialogActions, DialogContent, Card, CardContent, DialogTitle, Button, Collapse, Typography } from '@material-ui/core';
import { Cancel as CancelIcon, Info as InfoIcon } from '@material-ui/icons';

const useStyles = makeStyles({
    card: {
        marginTop: 10
    },
    textError: {
        overflow: "auto"
    }
  });

const RSDialogError = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch()
    const errorMessageTitle = (type) => {
        if (type === "Error"){
          return "Erro";
        }
        else if (type === "Warning"){
          return "Atenção";
        }
        return "";
    };
    const urlInLines = (url) => {        
        const total = url.length;
        const tamanho = 60;
        const linhas = total / tamanho;
        let paragrafo = [];
        let posicao = 0;
        for (let index = 0; index < linhas; index++) {            
            paragrafo.push(url.substring(posicao, posicao + tamanho));
            posicao = posicao + tamanho;
        }
        return paragrafo.join("<br />");
    };
    const [exibeDetalhes, setExibeDetalhes] = useState(false);
    return (
        <Dialog open={props.visible} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{errorMessageTitle(props.type)}</DialogTitle>
            <DialogContent>                
                <Typography variant="caption" color="textSecondary" component="div">
                    <div dangerouslySetInnerHTML={{ __html: urlInLines(props.url) }} />
                </Typography>
                <br />
                <Typography gutterBottom variant="h5" component="h2">
                    <div dangerouslySetInnerHTML={{ __html: props.message }} />
                </Typography>
                <Collapse in={exibeDetalhes} timeout="auto" unmountOnExit>
                    <Card className={classes.card}>
                        <CardContent>
                            <Typography variant="body2" color="textSecondary" component="p" className={classes.textError}>
                                {props.trace}
                            </Typography>
                        </CardContent>
                    </Card>
                </Collapse>
                <DialogActions>
                    {props.trace && (
                        <Button 
                            color={'primary'} 
                            size="small" 
                            onClick={() => setExibeDetalhes(!exibeDetalhes)}                         
                        >
                            <InfoIcon />&nbsp;Detalhes
                        </Button>
                    )}                
                    <Button 
                        color={props.type === "Error" ? 'secondary' : 'primary'} 
                        size="small" 
                        onClick={() => dispatch({ type: 'CLEAR_ERROR', payload: {} })} 
                        autofocus={true}
                    >
                        <CancelIcon />&nbsp;Fechar
                    </Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    )
}

export default RSDialogError;