import { makeStyles } from '@material-ui/styles';
import theme from '../../theme';
 
const useStyles = makeStyles({
    textField: {
        width: '100%',
        maxWidth: '100%',
        marginRight: theme.spacing() * 3
    },
    bottomBarModal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: 5
    },
    modalTitulo:{
        paddingLeft: 20,
        marginTop: 10,
        color: "#12161B",
        fontSize: 16,
        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    },
    modalContent: {
        borderRadius: 10,
        position: 'absolute',
        backgroundColor: "#FFFFFF",
    },
})

export default useStyles;