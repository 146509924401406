import * as types from '../types/transacao'
import queryString from 'query-string'
import http from '../../services/http'
import store from '../index'

export const loadTransacoes = filtro => {

    const qry = queryString.stringify(filtro,{ arrayFormat: 'index',  });
    return dispatch => {
      dispatch(
         { type: types.LOADING_TRANSACOES, payload: true  }, );
        http.get(`/api/v1/adm/transacao/listar?${qry}`).then(
        resp => {     
         const {total, current_page: page, per_page, data, grafico } = resp.data
          dispatch([
            {type: types.LISTA_TRANSACOES, payload: data},
            {type: types.SET_TRANSACOES_PAGE, payload: page},
            {type: types.SET_TRANSACOES_PERPAGE, payload: per_page },
            {type: types.SET_TRANSACOES_TOTAL, payload: total },
            {type: types.GRAFICO_TRANSACOES, payload: grafico },
            { type: types.LOADING_TRANSACOES, payload: false },
          ]);
        },
        (e) => dispatch({ type: types.LOADING_TRANSACOES, payload: false })
      );
    }
  };

export const visualizarTransacao = (tid) => {
    return dispatch => {
        http.get(`/api/v1/adm/transacao/visualizar?transacao_id=${tid}`).then(
            resp=>{
                let response = resp.data
                dispatch([
                    {type: types.TRANSACTION_SELECTED, payload: response.transaction},
                    {type: types.TRANSACTION_CLI_SELECTED, payload: response.cli_dados},
                    {type: types.TRANSACTION_HISTORY_SELECTED, payload: response.historico}
                ])
            },
            error=>{
                console.log(error)
            }
        )
    }
}


export const estornarTransacao = (tid, success) => {
    const transacao_id = {tid}
    return new Promise( (resolve, reject) => {
        http.post(`api/v1/adm/transacao/estornar`,transacao_id).then(
            resp => {
                resolve(resp.data)
                success()
            },
            error => {
                console.log(error)
                reject(error)
            }
        )
    })
}