import React, { forwardRef } from 'react';
import MaterialTable from 'material-table';
import {AddBox, ArrowDownward, Check, ChevronLeft, ChevronRight, Clear, DeleteOutline, Edit,
    FilterList, FirstPage, LastPage, Remove, SaveAlt, Search, ViewColumn
} from '@material-ui/icons';

const RSDataGrid = (props) => {
    const { 
        title, columns, data, showPaging, showSearch, showTitle, showToolbar, actions, 
        onChangePage, onChangeRowsPerPage, onClick, onRowClick, options, detailPanel, onSelectionChange
    } = props;

    const tableIcons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
    };

    const localization  = {
        pagination: {
            labelDisplayedRows: '{from}-{to} de {count}',
            labelRowsSelect: 'registros',
            labelRowsPerPage: 'Registros por página',
            firstAriaLabel: 'Primeira página',
            firstTooltip: 'Primeira página',
            previousAriaLabel: 'Página anterior',
            previousTooltip: 'Página anterior',
            nextAriaLabel: 'Próxima página',
            nextTooltip: 'Próxima página',
            lastAriaLabel: 'Última página',
            lastTooltip: 'Última página',
        },
        toolbar: {
            nRowsSelected: '{0} linha(s) selecionadas',
            addRemoveColumns: 'Adicionar ou remover colunas',
            showColumnsTitle: 'Exibir Colunas',
            showColumnsAriaLabel: 'Exibir Colunas',
            exportTitle: 'Exportar',
            exportAriaLabel: 'Exportar',
            exportName: 'Exportar como CSV',
            searchTooltip: 'Buscar',
            searchPlaceholder: 'Buscar'
        },
        header: {
            actions: 'Ações'
        },
        body: {
            emptyDataSourceMessage: 'Não existe registros para serem exibidos',
            filterRow: {
                filterTooltip: 'Filtro'
            },
            addTooltip: 'Adicionar',
            deleteTooltip: 'Excluir',
            editTooltip: 'Editar',
            editRow:{
                deleteText: 'Deseja realmente excluir esse registro ?',
                cancelTooltip: 'Cancelar',
                saveTooltip: 'Gravar'
            }
        },
        grouping: {
            placeholder: 'Arraste o cabeçalho'
        }
    }

    const gridOptions = {
        paging: showPaging, 
        padding: 'dense',
        search: showSearch, 
        showTitle: showTitle, 
        toolbar: showToolbar,
        actionsColumnIndex: columns.length
    }

    return (
      <div id={props.id}>
        <MaterialTable 
            style={{ width: "100%" }}
            localization={localization}
            icons={tableIcons}
            columns={columns}
            data={data}
            title={title}
            options={{...gridOptions, ...options}}
            actions={actions}
            onChangePage={onChangePage}
            onChangeRowsPerPage={onChangeRowsPerPage}
            onClick={onClick}
            onRowClick={onRowClick}
            detailPanel={detailPanel}
            onSelectionChange={onSelectionChange}
        />
      </div>
    );
}

export default RSDataGrid;
