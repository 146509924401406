import React, { useState } from "react";
import { InputSelect, RSTextField } from "../../components";
import { Grid } from "@material-ui/core";
import data from "./bancos";
import http from './../../services/http/index'
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

function CadastrodeConta({returnState}) {

  const navigate = useNavigate();


  const [conta, setConta] = useState({
    codigo_banco: "",
    agencia: "",
    conta: "",
    digito: "",
    titular: "",
    tipo_conta: "",
  });

  const SendForm = (dados) => {
    return new Promise( (resolve, reject) => {
    http.post(`/api/v1/adm/contas/cadastrar`, dados).then(
      response => resolve(response.data),
      err => reject(err)
    )
   })
  }

  const Toast = Swal.mixin({
    toast: true,
    position: "bottom-right",
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
    

  async function onSubmit(event){
    event.preventDefault();  
    try {
      await SendForm(conta)
      Toast.fire({
        icon: "success",
        title: "Conta Cadastrada com sucesso!",
      });
      returnState()
    } catch (error) {
      Toast.fire({
        icon: "error",
        title: "Verifique os dados enviados!",
      });
    }
  }

  return (
    <div style={{ padding: "2em" }}>
      <h4>Cadastro de Contas</h4>
      <br />
      <Grid container item spacing={2} style={{ padding: "2em" }}>
        <Grid item sm={3}>
          <InputSelect
            name="codigo_banco"
            label="Bancos"
            values={[
              { value: "",    label: "Selecione" },
              { value: "001", label: "001 - Banco do Brasil S.A" },
              { value: "033", label: "033 - Banco Santander (Brasil) S.A." },
              { value: "104", label: "104 - Caixa Econômica Federal" },
              { value: "237", label: "237 - Banco Bradesco S.A." },
              { value: "341", label: "341 - Banco Itaú S.A." },
              { value: "356", label: "356 - Banco Real S.A." },
              { value: "389", label: "389 - Banco Mercantil do Brasil S.A." },
              { value: "399", label: "399 - HSBC Bank Brasil S.A."},
              { value: "422", label: "422 - Banco Safra S.A." },
              { value: "453", label: "453 - Banco Rural S.A." },
              { value: "633", label: "633 - Banco Rendimento S.A." },
              { value: "652", label: "652 - Itaú Unibanco Holding S.A." },
              { value: "745", label: "745 - Banco Citibank S.A." },
            ]}
            value={conta.codigo_banco}
            onChange={(e) => setConta({ ...conta, codigo_banco: e.target.value })}
          />
        </Grid>
        <Grid item sm={2}>
          <RSTextField
            value={conta.agencia}
            label="Agencia"
            variant="outlined"
            onChange={(event) =>
              setConta({ ...conta, agencia: event.target.value })
            }
          />
        </Grid>
        <Grid item sm={2}>
          <RSTextField
            value={conta.conta}
            label="Conta"
            variant="outlined"
            onChange={(event) =>
              setConta({ ...conta, conta: event.target.value })
            }
          />
        </Grid>
        <Grid item sm={2}>
          <RSTextField
            value={conta.digito}
            label="Dígito"
            variant="outlined"
            onChange={(event) =>
              setConta({ ...conta, digito: event.target.value })
            }
          />
        </Grid>   
        <Grid item sm={3}>
          <RSTextField
            value={conta.titular}
            label="Titular"
            variant="outlined"
            onChange={(event) =>
              setConta({ ...conta, titular: event.target.value })
            }
          />
        </Grid>
        <div style={{ marginTop: "2em", textAlign: "right", width: "100%" }}>



          <button
            onClick={onSubmit}
            style={{
              padding: "1.2em 2.4em",
              border: "none",
              cursor: "pointer",
              borderRadius: "4px",
              marginRight: "2em",
              backgroundColor: "#1E4766",
              color: "#fff",
              fontSize: "10px",
            }}
          >
            CADASTRAR
          </button>
        </div>
      </Grid>
    </div>
  );
}
export default CadastrodeConta;
