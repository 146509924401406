import React from 'react';
import useStyles from './styles';

import { Backdrop, CircularProgress } from '@material-ui/core';

const RSLoading = (props) => {
    const classes = useStyles();

    return (
        <Backdrop className={classes.backdrop} open={props.loading}>
            <CircularProgress color="inherit" disableShrink={true} />
        </Backdrop>
    )
}

export default RSLoading;