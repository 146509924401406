import { makeStyles } from '@material-ui/styles';
import theme from '../../theme';

const useStyles = makeStyles({
    root: {
        position: "relative", 
        width: 100+'%', 
        height: 60, 
        paddingLeft: 15, 
        paddingRight: 15, 
        paddingBottom: 5,
        zIndex: 99
    },
    mt10: {
        marginTop: 10
    },
    filter: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        flex: 1,
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 28,
        margin: 4,
    }
    // mt10:{
    //     marginTop: 15
    // },
});

export default useStyles;