import {
  Button,
  Grid,
  Paper,
  Card,
  CardContent,
  Typography,
} from "@material-ui/core";
import { ViewModule } from "@material-ui/icons";
import React, { useSate } from "react";
import { makeStyles } from "@material-ui/styles";
import { PortletHeader, RSDataGrid, RSPaginate } from "../../../components";
import Budget from "./budgetTopList";
import PerfectScrollBar from "react-perfect-scrollbar";
import dayjs from "dayjs";

// const mocados = [
//   {
//     data: "20/01/2021",
//     id_operacao: "2345678",
//     id_transacao: "321",
//     transacao: "233",
//     entrada: "25",
//     saida: "35",
//     total: "60",
//   },
// ];

const TableListExt = (props) => {
  const { dados_periodo, per_page, page, total } = props.dados;
  const {lista_extrato} = dados_periodo

  const {handleChangeTransactionPage, handleChangeTransactionRowsPerPage} = props.changes

  const useStyles = makeStyles({
    root: {
     width: '96%',
     margin: 'auto'
    },
    bullet: {
      display: "inline-block",
      margin: "0 2px",
      transform: "scale(0.8)",
    },
    title: {
      fontSize: 12,
    },
    pos: {
      marginBottom: 12,
    },
    centralizar: {
      textAlign: "center",
    },
    mtop: {
      marginTop: "10%",
    },
  });

  const classes = useStyles()
  const textToolTip = {
    totalEntradas: "Todos os lançamentos de vendas brutas",
    totalSaidas:
      "Todos os lançamentos de transferências, chargebacks e estornos",
    taxasCXPay:
      "Todos os descontos de tarifas de cartão, gateway, boletos e TEDS",
    totalLiquido:
      "Todos os lançamentos de vendas descontando as taxas, chargebacks e estornos",
  };

  return (
    <Grid
      container
      sm={12}
      spacing={1}      
      className={classes.root}
    >
      <Budget
        grid={3}
        titleColor="#65A300"
        toolTipTitle={textToolTip.totalEntradas}
        title="Total de Entradas"
        style={{ border: "none", boxShadow: "0px 0px 9px 2px #eaeaea" }}
      >
        <span style={{color: "#595959",}}>
       <b style={{fontSize: '12px'}}>R$</b> {dados_periodo.entradas.toFixed(2)}
        </span>
      </Budget>

      <Budget
        grid={3}
        titleColor="#DF285F"
        toolTipTitle={textToolTip.totalSaidas}
        title="Total de saídas"
        style={{ border: "none", boxShadow: "0px 0px 9px 2px #eaeaea" }}
      >
         <span style={{color: "#595959",}}>
         <b style={{fontSize: '12px'}}>R$</b> {dados_periodo.saidas.toFixed(2)}
        </span>
      </Budget>

      <Budget
        grid={3}
        titleColor="#DF285F"
        toolTipTitle={textToolTip.taxasCXPay}
        title="Taxas Conexo Pay"
        style={{ border: "none", boxShadow: "0px 0px 9px 2px #eaeaea" }}
      >
      <span style={{color: "#595959",}}>
        <b style={{fontSize: '12px'}}>R$</b> {dados_periodo.taxas_cxpay.toFixed(2)}
        </span>
      </Budget>

      <Budget
        grid={3}
        titleColor="#4079BB"
        toolTipTitle={textToolTip.totalLiquido}
        title="Total liquido"
        style={{ border: "none", boxShadow: "0px 0px 9px 2px #eaeaea" }}
      >
      <span style={{color: "#595959",}}>
         <b style={{fontSize: '12px'}}>R$</b> {dados_periodo.liquido.toFixed(2)}
        </span>
      </Budget>

      <Grid container sm={12}></Grid>

      <Grid sm={12} container style={{ marginTop: 50 }}>
        <Grid sm={4}></Grid>
        <Grid sm={2}>
          
        </Grid>

        <Grid sm={12}>
          <PerfectScrollBar>
            <RSDataGrid
              columns={[
                {
                  title: "DATA",
                  field: "transacao_data",
                  render: rowData => dayjs(rowData.transacao_data).format('DD/MM/YYYY')
                },
                {
                  title: "AUT. OPERAÇÃO",
                  field: "numero_comprovante",
                },
                {
                  title: "ID DA TRANSAÇÃO",
                  field: "transacao_id",
                },
                {
                  title: "PARCELAS RECEBIDAS",
                  field: "parc_pag",
                },
                {
                  title: "PARCELAS A RECEBER",
                  field: "parc_res",
                },
                {
                  title: "TOTAL PARCELAS",
                  field: "transacao_parcelas",
                },
                {
                  title: "VALOR DA PARCELA",
                  field: "val_parc",
                },
                {
                  title: "VALOR RECEBIDO",
                  field: "val_pag",
                },
                {
                  title: "VALOR A RECEBER",
                  field: "val_res",
                },
                {
                  title: "VALOR TOTAL",
                  field: "val_tot",
                },
              ]}
              data={lista_extrato}
              options={{
                headerStyle: {
                  backgroundColor: "#F2F2F3",
                  fontSize: 14,
                  textAlign: "center",
                  borderLeft: "solid 0.5px white",
                },
                cellStyle: {
                  textAlign: "center",
                  fontSize: 14,
                },
                rowStyle: (rowData) => ({
                  fontSize: 20,
                }),
              }}
            />
            <RSPaginate
              rowsPerPageOptions={[5, 10, 20, 30]}
              rowsPerPage={per_page}
              count={total}
              page={page - 1}
              onChangePage={(event, newPage) => handleChangeTransactionPage(newPage)}
              onChangeRowsPerPage={(e) => handleChangeTransactionRowsPerPage(e)}
            />
          </PerfectScrollBar>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TableListExt;
