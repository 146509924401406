import React, { } from 'react';
import { TextField, FormControlLabel, Checkbox } from '@material-ui/core';
import useStyles from './styles';
import PropTypes from 'prop-types';
import MomentUtils from "@date-io/moment";
import moment from "moment";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import "moment/locale/pt-br";
import RSMaskField from '../RSMaskField';
import NumberFormat from 'react-number-format';

moment.locale('pt-BR');
const RSTextField = (props) => {
    const classes = useStyles();

    function typeinput(type){
      switch (type) {
        case "INPUT": return textField()
        case "DATE": return dateField()
        case "DATETIME": return dateTimeField()
        case "SELECT": return selectField()
        case "MASK": return maskedField()
        case "CHECK": return checkField()
        case "MONEY": return editMoney()
        case "NUMBER": return editNumber()
        default: return textField()
      } 
    }

    function textField(){
      return (
        <TextField
          {...props}
          className={classes.textField}
          label={props.label}
          margin="dense"
          required={props.required}
          value={props.value || ""}
          onChange={props.onChange}
          inputProps={{maxLength:props.maxLength}}
          InputLabelProps={{ shrink: true, }}
        />
      )
    }

    function dateTimeField(){
      return (
        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={'pt-BR'}>
          <KeyboardDatePicker
            {...props}
            className={classes.textField}
            disableToolbar
            format="DD/MM/YYYY HH:mm"
            margin="dense"          
            orientation="landscape"
            label={props.label}
            value={props.value || ""}
            onChange={props.onChange}
            clearLabel="Limpar"
            clearable={true}
            cancelLabel="Cancelar"
            showTodayButton={true}
            todayLabel="Hoje"
            autoOk={true}
            inputVariant="standard"
            InputLabelProps={{ shrink: true, }}
          />
        </MuiPickersUtilsProvider>
      )
    }

    function dateField(){
      return (
        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={'pt-BR'}>
          <KeyboardDatePicker
            {...props}
            className={classes.textField}
            disableToolbar
            format="DD/MM/YYYY"
            margin="dense"          
            orientation="landscape"
            label={props.label}
            value={props.value || ""}
            onChange={props.onChange}
            clearLabel="Limpar"
            clearable={true}
            cancelLabel="Cancelar"
            showTodayButton={true}
            todayLabel="Hoje"
            autoOk={true}
            inputVariant="standard"
            InputLabelProps={{ shrink: true, }}
          />
        </MuiPickersUtilsProvider>
      )
    }

    function selectField(){
      return (
        <TextField
          {...props}
          className={classes.textField}
          select
          label={props.label}
          margin="dense"
          //variant="inline"
          value={props.value || ""}
          onChange={props.onChange}
          SelectProps={props.selopt || {native: true}}
          InputLabelProps={{ shrink: true, }}
        >
        {
          props.values !== undefined ?
            props.values.map( (option, index, arr)  => (<option key={index} value={option.value || ""}>{option.label}</option>))
          : null
        }
      </TextField>
      )
    }

    function editNumber(){
      const { onChange, onBlur, value } = props;

      return (
          <NumberFormat 
            style={{width: 100+'%'}}
            id={props.id}
            customInput={TextField} 
            allowEmptyFormatting={true}
            allowLeadingZeros={true}
            fixedDecimalScale={true}
            displayType={'input'}
            decimalScale={props.decimalScale}
            decimalSeparator={props.separator || '.'}
            onChange={onChange}
            onBlur={onBlur}
            value={value || ""}
            margin="dense"
            label={props.label}
            required={props.required}
            disabled={props.disabled}
            InputLabelProps={{ shrink: true, }}            
          />
      )
    }

    function editMoney(){
      return (
        <RSMaskField 
          {...props} 
          className={classes.textField}
          isnumber={true} 
          onChange={props.onChange}
          value={props.value || ""}
          required={props.required}
          margin="dense"
          label={props.label}
          />
      )
    }

    function maskedField(){
      return (
        <RSMaskField 
          {...props} 
          className={classes.textField}
          isnumber={false} 
          onChange={props.onChange}
          value={props.value || ""}
          required={props.required}
          margin="dense"
          label={props.label}
          mask={props.mask}
          />
      )
    }

    function checkField(){
      return (
        <FormControlLabel 
          control={
          <Checkbox 
            {...props}
            color={props.color || "primary"}
            checked={props.value}
            value=""
            onChange={props.onChange}
          />
        } 
        label={props.label}
        />                                        
      )
    }

    return typeinput(props.typeinput)
}

RSTextField.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default RSTextField;
