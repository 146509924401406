import React from 'react';
import MaskedInput from 'react-text-mask';
import NumberFormat from 'react-number-format';
import { TextField } from '@material-ui/core';

function TextMaskCustom(props) {
    const { inputRef, ...other } = props;

    return (
      <MaskedInput
        {...other}
        ref={ref => { inputRef(ref ? ref.inputElement : null); }}
        mask={props.mask}
        keepCharPositions={false}
        guide={false}
        placeholderChar={'\u2000'}
        showMask        
      />
    );
}

function NumberFormatCustom(props) {
    const { inputRef, onChange, value, ...other } = props;
  
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        thousandSeparator={'.'}
        isNumericString
        prefix="R$ "
        decimalScale={2}
        decimalSeparator={','}
        fixedDecimalScale={true}
        onValueChange={values => {
          onChange({
            target: {
              values,
            },
          });
        }}
        value={value}
      />
    );
  }

const RSMaskField = (props) => {
    const { endAdornment } = props;  
    return props.isnumber === false ?
    (        
        <TextField
            {...props}
            className={props.className}
            label={props.label}
            value={props.value || ""}
            onChange={props.onChange}
            id={props.id}
            InputLabelProps={{ shrink: true, }}
            InputProps={{
                inputComponent: TextMaskCustom,
                inputProps: {...props},
                endAdornment
            }}  
        />
    ):(
        <TextField
            {...props}
            className={props.className}
            label={props.label}
            value={props.value || ""}
            onChange={props.onChange}
            id={props.id}
            InputLabelProps={{ shrink: true, }}
            InputProps={{
                inputComponent: NumberFormatCustom,
                inputProps: {...props}
            }}
        />
    );
}

        
export default RSMaskField;