import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    ListSubheader,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Collapse,
} from '@material-ui/core';
import { 
    InboxIcon,
    DraftsIcon,
    SendIcon,
    ExpandLess,
    ExpandMore,
    StarBorder,
} from '@material-ui/icons';

import {
  DollarSign as DollarSignIcon
} from 'react-feather';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

export default function Saldo() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      // subheader={
      //   <ListSubheader component="div" id="nested-list-subheader">
      //     Saldo
      //   </ListSubheader>
      // }
      className={classes.root}
    >
      <ListItem button onClick={handleClick}>
        <ListItemIcon>
          {/* <InboxIcon /> */}
        </ListItemIcon>
        <ListItemText primary="Saldo" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem button className={classes.nested}>
            <ListItemIcon>
              <DollarSignIcon />
            </ListItemIcon>
            <ListItemText primary="99.90" />
          </ListItem>
        </List>
      </Collapse>
    </List>
  );
}