import * as types from '../types/transacao'

const INITIAL_STATE = {
    loading: false,
    lista_transacoes: [],
    transaction_page: 1,
    transaction_perpage: 10,
    transaction_total: 0,
    transaction_selected: {},
    transaction_cli_selected: undefined,
    transaction_history_selected: [],
    grafico_transacoes: [],
}

export default (state = INITIAL_STATE, action) => {
    switch(action.type){
      case types.LOADING_TRANSACOES:
        return {...state, loading: action.payload}
      case types.LISTA_TRANSACOES:
        return {...state, lista_transacoes: action.payload}
      case types.SET_TRANSACOES_PAGE:
        return {...state, transaction_page: action.payload}
      case types.SET_TRANSACOES_PERPAGE:
        return {...state, transaction_perpage: action.payload}
      case types.SET_TRANSACOES_TOTAL:
        return {...state, transaction_total: action.payload}
      case types.TRANSACTION_SELECTED:
        return {...state, transaction_selected: action.payload}
      case types.TRANSACTION_CLI_SELECTED:
        return {...state, transaction_cli_selected: action.payload}
      case types.TRANSACTION_HISTORY_SELECTED:
        return {...state, transaction_history_selected: action.payload}
      case types.GRAFICO_TRANSACOES:
        return {...state, grafico_transacoes: action.payload}
      default:
        return state
    }
  }