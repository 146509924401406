import React from 'react';
import { Dialog as DialogMaterial, DialogTitle, DialogContent, Backdrop,
DialogContentText, DialogActions, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';


const useStyles = makeStyles({
  buttonDialog:{
    padding: '13px 27px',
    border: 'none',
    textAlign: 'center',
    borderRadius: '4px'
  },
  btnContainer:{
    width: '90%',
    margin: 'auto',
    marginBottom: '2em',
    marginTop: '3em'
  },
  containerDialog: {
    width: '90%',
    margin: 'auto',
     '& h2':{
       fontSize: '24px',
       fontWeight: 'normal',
       lineHeight: '30px',
      marginTop: '2em'
     }
  },
  container:{
    width: '30%',
    margin: 'auto',
  }
  
})

const Dialog = (props) => {
  const { open = false, renderAsHtml = false, contenttext, children, title, buttons } = props;

  const classes = useStyles()

  return (
    <DialogMaterial
      open={open}    
      className={classes.container}
    >
      <div className={classes.containerDialog}> 
      <DialogTitle><h2>{title}</h2></DialogTitle>
      <DialogContent>
        {renderAsHtml && (<div dangerouslySetInnerHTML={{__html: contenttext}}></div>)}
        {contenttext && (<DialogContentText>{contenttext}</DialogContentText>)}
        {children}
      </DialogContent>
        <div className={classes.btnContainer}>
      <Grid container spacing={1}>
        {buttons.map((button, index) => (
          <Grid sm={props.size || 6} style={{textAlign :'center'}} >
          <button
            style={{...button.style, cursor: 'pointer' }}
            disabled={button.disabled}
            color={button.color}
            onClick={() => button.onClick()}
            key={index}
            className={classes.buttonDialog}
          >
            {button.icon}&nbsp;{button.title}
          </button>
      </Grid>
        ))}
      </Grid>
        </div>
        </div>
    </DialogMaterial>
  );
};

export default Dialog;