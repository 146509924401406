import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Grid, Paper, Tabs, Tab, Box, Button } from "@material-ui/core";
import dayjs from "dayjs";

import { Budget } from "../Resumo/components";

import * as dashType from "../../store/types/dashboard";
import * as transactionType from "../../store/types/transacao";
import TableList from "./components/tableList";
import GraficoTrans from "./components/graficoTransacoes";

import {
  Portlet,
  RSHeaderBar,
  RSModal,
  PortletLabel,
  RSTextField,
  FilterBar as Filter,
  InputSelect,
  RSLoading,
  InputSearch,
} from "../../components";
import * as types from "../../store/types/resumo";
import * as typesTransaction from "../../store/types/transacao";

import useStyles from "./style";
import {
  Add as AddIcon,
  CalendarToday as CalendarTodayIcon,
  Save as SaveIcon,
  Close as CloseIcon,
  ExpandMore as ExpandMoreIcon,
  FilterList as FilterListIcon,
  Clear as ClearIcon,
  Search as SearchIcon,
  TrendingUp as TrendingUpIcon,
  FormatListBulleted as FormatListBulletedIcon,
} from "@material-ui/icons";

import { loadTransacoes } from "../../store/actions/transacao";

const Resumo = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const extrato = useSelector((state) => state.extrato);
  const daysResumo = useSelector((state) => state.dashboard);
  const { resumo_estatisticas } = useSelector((state) => state.resumo);
  const {
    loading,
    lista_transacoes,
    transaction_page,
    transaction_perpage,
    transaction_total,
    grafico_transacoes,
  } = useSelector((state) => state.transacao);

  const [modals, setModals] = useState({ calendario: false });

  const [pesquisa, setPesquisa] = useState({
    bandeira: "",
    parcelas: "",
    formas_pagamento: "",
    opcoes_recusa: "",
    status_transacao: "",
    nome_cartao: "",
  });

  const [tabValue, setTabValue] = useState(0);

  const trocaTab = (event, newValue) => {
    setTabValue(newValue);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return <> {value === index && <Box>{children}</Box>} </>;
  }

  const updateList = () => {
    showLoading();
    dispatch(loadTransacoes({ ...daysResumo }));
  };

  const showLoading = () => {
    dispatch([{ type: typesTransaction.LOADING_TRANSACOES, payload: true }]);
  };

  function handleChangeTransactionPage(newPage) {
    dispatch([
      { type: transactionType.SET_TRANSACOES_PAGE, payload: newPage + 1 },
      loadTransacoes({
        ...daysResumo,
        page: newPage + 1,
        per_page: transaction_perpage,
      }),
    ]);
  }

  const handleChangeTransactionRowsPerPage = (event) => {
    dispatch([
      { type: transactionType.SET_TRANSACOES_PAGE, payload: 1 },
      {
        type: transactionType.SET_TRANSACOES_PERPAGE,
        payload: parseInt(event.target.value, 10),
      },
      loadTransacoes({
        ...daysResumo,
        page: 1,
        per_page: parseInt(event.target.value, 10),
      }),
    ]);
  };

  const modifyDataInicio = (dias_red) => {
    dispatch([
      {
        type: dashType.SET_PERIODO_INICIO,
        payload: dayjs().subtract(dias_red, "day").format("YYYY-MM-DD"),
      },
    ]);
  };

  const modifyData = (type, newData) => {
    if (type == "inicio") {
      dispatch([{ type: dashType.SET_PERIODO_INICIO, payload: newData }]);
    } else if (type == "fim") {
      dispatch([{ type: dashType.SET_PERIODO_FIM, payload: newData }]);
    } else {
      console.log("tipo desconhecido!");
    }
  };

  const [buttonsTop, setButtonsTop] = useState([
    {
      danger: false,
      title: "Hoje",
      onClick: () => {
        modifyDataInicio(0);
        Refresh();
      },
    },
    {
      danger: false,
      title: "7 Dias",
      onClick: () => {
        modifyDataInicio(7);
        Refresh();
      },
    },
    {
      danger: false,
      title: "15 Dias",
      onClick: () => {
        modifyDataInicio(15);
        Refresh();
      },
    },
    {
      danger: false,
      title: "30 Dias",
      onClick: () => {
        modifyDataInicio(30);
        Refresh();
      },
    },
    {
      danger: false,
      title: "45 Dias",
      onClick: () => {
        modifyDataInicio(45);
        Refresh();
      },
    },
    {
      danger: false,
      title: "60 Dias",
      onClick: () => {
        modifyDataInicio(60);
        Refresh();
      },
    },
    {
      danger: false,
      title: "Filtrar",
      icon: <CalendarTodayIcon />,
      onClick: () => {
        setModals({ ...modals, calendario: true });
      },
    },
  ]);

  const Refresh = () => {
    return dispatch([
      { type: types.LOADING_RESUMO, payload: true },
      updateList(),
    ]);
  };

  const clearFilter = () => {
    dispatch(
      loadTransacoes({
        ...pesquisa,
        bandeira: "",
        qtd_parcelas: "",
        formas_pagamento: "",
        opcoes_recusa: "",
        status_transacao: "",
        nome_cartao: "",
      })
    );
  };

  const pesquisar = async () => {
    dispatch(
      loadTransacoes({ ...pesquisa, page: 1, paginate: true, per_page: 10 })
    );
  };

  useEffect(() => {
    updateList();
  }, []);

  return (
    <div className={classes.root}>
      <RSLoading loading={loading} />
      <Portlet className={classes.titleMT}>
        <RSModal open={modals.calendario} modalWidth={600} modalHeight={400}>
          <PortletLabel title="Período" style={{ width: 100 + "%" }} />
          <Grid container style={{ marginTop: 30 }}>
            <Grid container item spacing={2}>
              <Grid item sm={6}>
                <RSTextField
                  typeinput="DATE"
                  label="Data Inicial"
                  value={daysResumo.data_inicio}
                  onChange={(event) =>
                    modifyData("inicio", dayjs(event).format("YYYY-MM-DD"))
                  }
                />
              </Grid>
              <Grid item sm={6}>
                <RSTextField
                  typeinput="DATE"
                  label="Data Final"
                  value={daysResumo.data_fim}
                  onChange={(event) =>
                    modifyData("fim", dayjs(event).format("YYYY-MM-DD"))
                  }
                />
              </Grid>
            </Grid>

            <Grid
              container
              direction="row"
              justify="flex-end"
              alignItems="center"
              style={{ marginTop: 60 }}
            >
              <Button
                color="primary"
                size="small"
                onClick={() => {
                  Refresh();
                  setModals({ ...modals, calendario: false });
                }}
              >
                {<SaveIcon />}&nbsp;Filtrar Período
              </Button>
              <Button
                color="secondary"
                size="small"
                onClick={() => {
                  setModals({ ...modals, calendario: false });
                }}
              >
                {<CloseIcon />}&nbsp;Cancelar
              </Button>
            </Grid>
          </Grid>
        </RSModal>
        <RSHeaderBar
          title={`Transações - ${dayjs(daysResumo.data_inicio).format(
            "DD/MM/YYYY"
          )} Até ${dayjs(daysResumo.data_fim).format("DD/MM/YYYY")}`}
          buttons={buttonsTop}
        />
        {/* <div style={{ width: "97.7%", margin: "auto" }}>
          <Grid container style={{ marginTop: "16px" }} spacing={1}>
            <Grid item sm={11} spacing={1}>
              <InputSearch
                label="Digite o nome do cliente"
                value={pesquisa.nome_cartao}
                variant="filled"
                onChange={(e) =>
                  setPesquisa({ ...pesquisa, nome_cartao: e.target.value })
                }
              />
            </Grid>
            <Grid item sm={1}>
              <button
                className={classes.buttonSearch}
                onClick={() => dispatch(loadTransacoes({ ...pesquisa }))}
              >
                Pesquisar
              </button>
            </Grid>
          </Grid>
        </div> */}
        <Grid container spacing={2} style={{ marginTop: "2em" }}>
          <Grid item xs={11} sm={11}>
            <Filter
              clearFilter={clearFilter}
              searchFilter={pesquisar}
              title="Clique aqui para ver as opções de filtro."
            >
              <Grid container spacing={1}>
                <Grid item sm={12}>
                  <InputSearch
                    label="Digite o nome do cliente"
                    value={pesquisa.nome_cartao}
                    variant="filled"
                    onChange={(e) =>
                      setPesquisa({ ...pesquisa, nome_cartao: e.target.value })
                    }
                  />
                </Grid>
                <Grid item sm={2}>
                  <InputSelect
                    name="bandeira"
                    label="Bandeira"
                    values={[
                      { value: "", label: "Todos" },
                      { value: "mastercard", label: "Master" },
                      { value: "visa", label: "Visa" },
                      { value: "elo", label: "Elo" },
                      { value: "hipercard", label: "Hipercard" },
                      { value: "aura", label: "Aura" },
                      { value: "jcb", label: "JCB" },
                      { value: "amex", label: "Amex" },
                      { value: "diners", label: "Diners" },
                      { value: "discover", label: "Discover" },
                    ]}
                    value={pesquisa.tipo}
                    onChange={(e) =>
                      setPesquisa({ ...pesquisa, bandeira: e.target.value })
                    }
                  />
                </Grid>
                <Grid item sm={2}>
                  <InputSelect
                    name="parcelas"
                    label="Parcelas"
                    values={[
                      { value: "", label: "Todos" },
                      { value: "1", label: "1x" },
                      { value: "2", label: "2x" },
                      { value: "3", label: "3x" },
                      { value: "4", label: "4x" },
                      { value: "5", label: "5x" },
                      { value: "6", label: "6x" },
                      { value: "7", label: "7x" },
                      { value: "8", label: "8x" },
                      { value: "9", label: "9x" },
                      { value: "10", label: "10x" },
                      { value: "11", label: "11x" },
                      { value: "12", label: "12x" },
                    ]}
                    value={pesquisa.tipo}
                    onChange={(e) =>
                      setPesquisa({ ...pesquisa, parcelas: e.target.value })
                    }
                  />
                </Grid>
                <Grid item sm={3}>
                  <InputSelect
                    name="formas_pagamento"
                    label="Formas de Pagamento"
                    values={[
                      { value: "", label: "Todos" },
                      { value: "boleto", label: "Boleto" },
                      { value: "debito", label: "Cartão de Débito" },
                      { value: "credito", label: "Cartão de Crédito" },
                      {
                        value: "credito_internacional",
                        label: "Cartão de Crédito Internacional",
                      },
                    ]}
                    value={pesquisa.tipo}
                    onChange={(e) =>
                      setPesquisa({
                        ...pesquisa,
                        formas_pagamento: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item sm={3}>
                  <InputSelect
                    name="opcoes_recusa"
                    label="Opções de Recusa"
                    values={[
                      { value: "", label: "Todos" },
                      { value: "operadora", label: "Operadora de cartão" },
                      {
                        value: "tempo_resposta",
                        label: "Tempo de resposta excedido",
                      },
                      { value: "antifraude", label: "Antifraude" },
                      { value: "erro_interno", label: "Erro Interno" },
                    ]}
                    value={pesquisa.tipo}
                    onChange={(e) =>
                      setPesquisa({
                        ...pesquisa,
                        opcoes_recusa: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item sm={2}>
                  <InputSelect
                    name="status_transacao"
                    label="Status de Transação"
                    values={[
                      { value: "", label: "Todos" },
                      { value: "P", label: " Pendente" },
                      { value: "N", label: " Negado" },
                      { value: "A", label: " Aprovado" },
                      { value: "F", label: " Finalizado" },
                      { value: "E", label: " Expirado" },
                      { value: "C", label: " Cancelado" },
                    ]}
                    value={pesquisa.tipo}
                    onChange={(e) =>
                      setPesquisa({
                        ...pesquisa,
                        status_transacao: e.target.value,
                      })
                    }
                  />
                </Grid>
              </Grid>
            </Filter>
            {/*   P - pendente, N - negado, A - aprovado, F - Finalizado, E - Expirado, C - Cancelado*/}
          </Grid>
          <Grid item xs={1} sm={1} style={{ marginTop: "-0.5em" }}>
            <button
              onClick={() => {
                trocaTab({}, 0);
              }}
              className={
                tabValue == 0
                  ? classes.btnTabSelected
                  : classes.btnTabNoSelected
              }
            >
              <FormatListBulletedIcon />
            </button>

            <button
              aria-label="Grafico"
              onClick={() => {
                trocaTab({}, 1);
              }}
              className={
                tabValue == 1
                  ? classes.btnTabSelected
                  : classes.btnTabNoSelected
              }
            >
              <TrendingUpIcon />
            </button>
          </Grid>
        </Grid>
        <div style={{ width: "97.7%", margin: "auto" }}>
          <Grid container item sm={12} style={{ flexBasis: "50%" }}>
            <Paper className={classes.rootTab}>
              <Tabs
                value={tabValue}
                onChange={trocaTab}
                indicatorColor="primary"
                textColor="primary"
                centered
                style={{ display: "none" }}
              >
                <Tab icon={<FormatListBulletedIcon />} value={0} />
                <Tab icon={<TrendingUpIcon />} value={1} />
              </Tabs>
              <TabPanel value={tabValue} index={0}>
                <TableList
                  dados={{
                    transaction_page,
                    transaction_perpage,
                    transaction_total,
                    lista_transacoes,
                  }}
                  changes={{
                    handleChangeTransactionPage,
                    handleChangeTransactionRowsPerPage,
                  }}
                />
              </TabPanel>

              <TabPanel value={tabValue} index={1}>
                <Grid
                  container
                  sm={12}
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <Budget grid={8} container title="Quantidade de Transações">
                    <GraficoTrans dados={grafico_transacoes} />
                  </Budget>
                </Grid>
              </TabPanel>
            </Paper>
          </Grid>
        </div>
      </Portlet>
    </div>
  );
};

export default Resumo;
